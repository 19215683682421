/* 
 * This file contains all buttons 
 *
 * .btn - base class for Button component
*/

.btn {
  border-width: 2px;
  border-style: solid;
  box-sizing: border-box;
  display: inline-block;
  font-size: @fontSize-base--button;
  font-family: @fontFamily-bold--button;
  margin: 10px 0;
  padding: 6px 12px;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
}
.btn[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
.btn .ofa-icon {
  display: inline-block;
  font-size: 19px;
  margin-top: -6px;
  margin-bottom: -2px;
  margin-right: 4px;
}
.btn .ofa-glyphicon {
  //margin-right: 6px;
  //vertical-align: middle;
  //font-size: initial;
  //margin-top: -4px;
  display: inline-block;
  //font-size: 19px;
  margin-top: -6px;
  margin-bottom: -2px;
  margin-right: 10px;
}
.btn:focus {
  outline: 0;
}
.btn--icon {
  padding:5px 5px 3px;
  line-height: 1em;
  font-size: 12px;
}
.btn--newsletter {
  height: 26px;
  width: 28px;
  padding: 0;
  margin: 0;
  margin-left: 2px;
  .ofa-icon {
    display: inline-block;
    font-size: 17px;
    margin-top: -1px;
    margin-left: 4px;
  }
}


/*
 * Primary button
 */
.btn--primary {
  background-color: @bg-brandNeutral--button;
  border-color: @borderColor-brandNeutral--button;
  color: @color-brandWhite--button
}
.btn--primary[class^="icon-"] {
  margin-right: 10px;
}
.btn--primary:hover {
  border-color: @borderColor-brandDark--button;
  background-color: @color-brandDark
}
.btn--primary[disabled]:hover {
  background-color: @bg-brandNeutral--button;
  border-color: @borderColor-brandNeutral--button;
}
/* Primary button is available in different colors */
.btn--primaryBlue {
  background-color: @bg-blueNeutral--button;
  border-color: @borderColor-blueNeutral--button;
  color: @color-brandWhite--button;
}
.btn--primaryBlue:hover {
  border-color: @borderColor-blueDark--button;
  background-color: @bg-blueDark--button;
}
.btn--primaryBlue[disabled]:hover {
  background-color: @bg-blueNeutral--button;
  border-color: @borderColor-blueNeutral--button;
}
.btn--primaryBlack {
  background-color: @color-brandBlack;
  border-color: @color-brandBlack;
  color: @color-brandWhite;
}
.btn--primaryBlack:hover {
  background-color: @color-brandBlack;
  border-color: @color-brandBlack;
}
.btn--primaryBlack[disabled]:hover {
  background-color: @color-brandBlack;
  border-color: @color-brandBlack;
}
/*
 * Secondary button
 */
.btn--secondary {
  background-color: @bg-brandWhite--button;
  border-color: @borderColor-brandNeutral--button;
  color: @color-brandNeutral--button;
}
.btn--secondary:hover,
.btn--secondary:focus,
.btn--secondary:focus-visible,
.btn--secondary:active {
  border-color: @borderColor-brandDark--button;
  color: @color-brandDark;
  outline: 0 !important;
}
.btn--secondary[disabled]:hover {
  background-color: @bg-brandWhite--button;
  border-color: @borderColor-brandNeutral--button;
}
.btn--third,
.btn--third[disabled]:hover {
  background-color:@color-blueDarker;
  border-color: @color-blueDarker;
  color: @color-brandWhite;
}
.btn--third:hover,
.btn--third:focus,
.btn--third:focus-visible,
.btn--third:active {
  border-color: @color-blueDarkest;
  background-color: @color-blueDarkest;
  color: @color-brandWhite;
  outline: 0 !important;
}
/*
 * Warning Button
 */
.btn--warning {
  background-color: @bg-brandWarning--button;
  border-color: @borderColor-brandWarning--button;
  color: @color-brandWhite--button;
}
.btn--warning:hover {
  border-color: darken(@borderColor-brandWarning--button, 10%);
  background-color: darken(@bg-brandWarning--button, 10%);
}

/*
 * Link button
 */
.btn--link {
  border: none;
  font-family: @fontFamily-italic--button;
  color: @color-brandNeutral--button;
  text-decoration: underline;
  margin: 0;
  cursor: pointer;
}

.btn--primary--noClick:hover {
  background-color: @color-brandNeutral !important;
  border-color: @color-brandNeutral !important;
  cursor: default;
}
@media (max-width: 1040px) {
  .btn {
    padding: 4px 8px;
  }
  .btn .ofa-icon {
    margin-right: 0;
  }
  .btn--newsletter {

  }
  .btn--newsletter .ofa-icon {
      margin-top: -3px;
      margin-left: -3px;
      &:before {
        margin-top: -7px;
      }
  }
}
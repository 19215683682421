@charset "UTF-8";
/*
 * This file imports all *.less files which are potentially used on the whole website.
 */
/*
 * FONT FACES
 *
 * All fonts are defined in this file. Never define a new font anywhere else in the project.
 * Always use TTF, WOFF, EOT and SVG files to ensure cross browser compatibility. 
 */
@font-face {
  font-family: 'Humanist Condensed Light';
  src: url("../fonts/humanist-condensed-light.eot");
  /* IE9 */
  src: url('../fonts/humanist-condensed-light.eot?#iefix') format('embedded-opentype'), url('../fonts/humanist-condensed-light.woff') format('woff'), url('../fonts/humanist-condensed-light.ttf') format('truetype'), url("../fonts/humanist-condensed-light.svg") format("svg");
}
@font-face {
  font-family: "Humanist Condensed Bold";
  src: url("../fonts/humanist-condensed-bold.eot");
  /* IE9 */
  src: url('../fonts/humanist-condensed-bold.eot?#iefix') format('embedded-opentype'), url('../fonts/humanist-condensed-bold.woff') format('woff'), url('../fonts/humanist-condensed-bold.ttf') format('truetype'), url("../fonts/humanist-condensed-bold.svg") format("svg");
}
@font-face {
  font-family: "Humanist Condensed Italic";
  src: url("../fonts/humanist-condensed-italic.eot");
  /* IE9 */
  src: url('../fonts/humanist-condensed-italic.eot?#iefix') format('embedded-opentype'), url('../fonts/humanist-condensed-italic.woff') format('woff'), url('../fonts/humanist-condensed-italic.ttf') format('truetype'), url("../fonts/humanist-condensed-italic.svg") format("svg");
}
@font-face {
  font-family: 'AGaramondPro-Italic';
  src: url('../fonts/AGaramondPro-Italic.eot?#iefix') format('embedded-opentype'), url('../fonts/AGaramondPro-Italic.otf') format('opentype'), url('../fonts/AGaramondPro-Italic.woff') format('woff'), url('../fonts/AGaramondPro-Italic.ttf') format('truetype'), url('../fonts/AGaramondPro-Italic.svg#AGaramondPro-Italic') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'ofa-portal';
  src: url('../fonts/ofa-portal.eot?gri14h');
  src: url('../fonts/ofa-portal.eot?gri14h#iefix') format('embedded-opentype'), url('../fonts/ofa-portal.ttf?gri14h') format('truetype'), url('../fonts/ofa-portal.woff?gri14h') format('woff'), url('../fonts/ofa-portal.svg?gri14h#ofa-portal') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "ofa-icons";
  src: url("../fonts/ofa-icons.eot");
  src: url("../fonts/ofa-icons.eot?#iefix") format("embedded-opentype"), url("../fonts/ofa-icons.woff") format("woff"), url("../fonts/ofa-icons.ttf") format("truetype"), url("../fonts/ofa-icons.svg#ofa-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}
.ofa-icon:before {
  display: inline-block;
  margin-top: -4px;
  margin-right: 4px;
}
.ofa-icon-small:before {
  font-size: 14px !important;
}
.ofa-icon--right {
  display: inline-block;
  margin-left: 5px;
  font-size: 15px !important;
  margin-right: -5px !important;
}
[data-icon]:before {
  font-family: "ofa-icons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^="icon-"]:before,
[class*=" icon-"]:before,
[class^="icon-"]:after,
[class*=" icon-"]:after {
  font-family: "ofa-icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  margin-top: -2px;
}
.icon-ofa-icon-information--redHover:hover:before,
.icon-ofa-icon-customer--redHover:hover:before {
  color: #b0053a;
}
.icon-ofa-icon-zurueck:before {
  content: "\50";
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1);
}
.icon-ofa-icon-zurueck-kreis:before {
  content: "\51";
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1);
}
.icon-ofa-icon-aendern:before {
  content: "\62";
}
.icon-ofa-icon-aendern-bestellen:before {
  content: "\63";
}
.icon-ofa-icon-alternativen:before {
  content: "\64";
}
.icon-ofa-icon-bestellhinweiss-leer:before {
  content: "\66";
}
.icon-ofa-icon-bestellhinweiss-voll:before {
  content: "\67";
}
.icon-ofa-icon-bestellubersicht:before {
  content: "\68";
}
.icon-ofa-icon-customer:before {
  content: "\69";
}
.icon-ofa-icon-drucken:before {
  content: "\6a";
}
.icon-ofa-icon-filter-bestellungen:before {
  content: "\6c";
}
.icon-ofa-icon-groessenfinder:before {
  content: "\6d";
}
.icon-ofa-icon-hinzufuegen:before {
  content: "\6e";
}
.icon-ofa-icon-information:before {
  content: "\6f";
}
.icon-ofa-icon-kontakt:before {
  content: "\70";
}
.icon-ofa-icon-kostenvoranschlag:before {
  content: "\71";
}
.icon-ofa-icon-kunde:before {
  content: "\72";
}
.icon-ofa-icon-kundenbestellung:before {
  content: "\73";
}
.icon-ofa-icon-kundenkartei:before {
  content: "\74";
}
.icon-ofa-icon-lager:before {
  content: "\75";
}
.icon-ofa-icon-lagerbestellung:before {
  content: "\76";
}
.icon-ofa-icon-messgeraet:before {
  content: "\77";
}
.icon-ofa-icon-nachkaufschreiben:before {
  content: "\78";
}
.icon-ofa-icon-nachsorgechreiben:before {
  content: "\79";
}
.icon-ofa-icon-neuer-kunde:before {
  content: "\7a";
}
.icon-ofa-icon-ortho-kinder:before {
  content: "\41";
}
.icon-ofa-icon-kleine-groessen:before {
  content: "\5A";
  font-size: 24px;
  margin-top: -8px;
}
.icon-ofa-icon-pfeil:before {
  content: "\42";
}
.icon-ofa-icon-pin-linie:before {
  content: "\43";
}
.icon-ofa-icon-rabatt:before {
  content: "\44";
}
.icon-ofa-icon-retoure-12:before,
.icon-ofa-icon-retoure:before {
  content: "\47";
}
.icon-ofa-icon-speichern:before {
  content: "\48";
}
.icon-ofa-icon-status-angekommen:before {
  content: "\49";
}
.icon-ofa-icon-status-bearbeitung:before {
  content: "\4a";
}
.icon-ofa-icon-status-erledigt:before {
  content: "\4b";
}
.icon-ofa-icon-status-offen:before {
  content: "\4c";
}
.icon-ofa-icon-suche:before {
  content: "\4d";
}
.icon-ofa-icon-warenkorb:before {
  content: "\4e";
}
.icon-ofa-icon-warnung:before {
  content: "\4f";
}
.icon-ofa-icon-weiter:before {
  content: "\50";
}
.icon-ofa-icon-weiter-kreis:before {
  content: "\51";
}
.icon-ofa-icon-filialkunde:before {
  content: "\52";
}
.icon-ofa-icon-loeschen:before {
  content: "\53";
}
.icon-ofa-icon-schliessen:before {
  content: "\54";
}
.icon-ofa-icon-filter:before {
  content: "\56";
}
.icon-ofa-icon-massbestellung:before {
  content: "\57";
}
.icon-ofa-icon-serienbestellung:before {
  content: "\58";
}
.icon-ofa-icon-pin-gefuellt:before {
  content: "\59";
}
.icon-ofa-icon-abmelden:before {
  content: "\61";
}
.icon-ofa-icon-account:before {
  content: "\65";
}
.icon-ofa-icon-anmelden:before {
  content: "\6b";
}
.icon-ofa-icon-fachhandelsportal:before {
  content: "\45";
}
.icon-ofa-icon-toleranzen:before {
  content: "\46";
}
.icon-ofa-icon-schnellbestellung:before {
  content: "\55";
}
[class^="icon-portal-"]:before,
[class*=" icon-portal-"]:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ofa-portal' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-portal-download:before {
  content: "\e906";
}
.icon-portal-aktualisieren:before {
  content: "\e90a";
}
.icon-portal-beliebte-produkte:before {
  content: "\e90b";
}
.icon-portal-burgermenu:before {
  content: "\e90c";
}
.icon-portal-drucken:before {
  content: "\e90d";
}
.icon-portal-filter:before {
  content: "\e90e";
}
.icon-portal-info:before {
  content: "\e90f";
}
.icon-portal-karriere:before {
  content: "\e910";
}
.icon-portal-koerperwissen:before {
  content: "\e911";
}
.icon-portal-krankheitsbild:before {
  content: "\e912";
}
.icon-portal-loeschen:before {
  content: "\e913";
}
.icon-portal-pfeil:before {
  content: "\e914";
}
.icon-portal-pin:before {
  content: "\e915";
}
.icon-portal-presse:before {
  content: "\e916";
}
.icon-portal-produktfinder:before {
  content: "\e917";
}
.icon-portal-schlieen:before {
  content: "\e918";
}
.icon-portal-speichern:before {
  content: "\e919";
}
.icon-portal-suche:before {
  content: "\e91a";
}
.icon-portal-therapie:before {
  content: "\e91b";
}
.icon-portal-weiter:before {
  content: "\e91c";
}
.icon-portal-weiter-kreis:before {
  content: "\e91d";
}
.icon-portal-facebook:before {
  content: "\e900";
}
.icon-portal-twitter:before {
  content: "\e901";
}
.icon-portal-instagram:before {
  content: "\e902";
}
.icon-portal-googleplus:before {
  content: "\e903";
}
.icon-portal-linkedin:before {
  content: "\e904";
}
.icon-portal-xing:before {
  content: "\e905";
}
/****************************************************************************************************************
 *
 * BEGIN OF FONTS
 * 
 * The base for all the fonts is Humanist Condensed.
 * Font faces are defined in fonts.less.
 *
 * FONT WEIGHTS
 */
/*
 * Basic component fonts
 *
 * Starting with headline stuff
 */
/* 
 * Tables
 */
/*
 * Tag Label
 */
/* 
 * Filter
 */
/*
 * Pagination
 */
/*
 * Label
 */
/*
  * Buttons
  */
/*
 * Anchors
 */
/*
 * Body Text
 */
/*
 * FONT SIZES
 */
/*
 * Button font sizes
 */
/*
 * END OF FONTS
 ****************************************************************************************************************/
/*
 * MEDIA QUERIES
 ****************************************************************************************************************/
/*
 * END OF MEDIA QUERIES
 ****************************************************************************************************************/
/****************************************************************************************************************
 *
 * BEGIN OF COLORS
 *
 *
 * Official Ofa brand colors
 */
/*
 * Generics
 */
/*
 * Ofa blues
 */
/*
 * Every product brand has a different main color. 
 */
/*
 * Button colors
 */
/* 
 * textInput variables
 */
/*
 * Input Groups
 */
/*
 * hints
 */
/*
 * placeholder variables
 */
/*
 * textLabel variables
 */
/*
 * Navbar Item
 */
/*
 * Shadow Box
 */
/*
 * Sidetabs
 */
/*
 * keyValue component
 */
/*
 * Message
 */
/*
 * Select Dropdown component
 */
/*
 * Table component
 */
/*
 * Modal Header
 */
/*
 * Hints
 */
/*
 * Login Overlay
 */
/*
 * Horizontal lines
 */
/*
 * Tiles
 */
/*
 * END OF FONTS
 ****************************************************************************************************************/
/****************************************************************************************************************
 *
 * BEGIN OF MEDIA QUERIES
 *
 *
 * Media-Queries used in the project.
 * Use in less files: @media @media--tablet-portrait { .selectEverything{ ... } }
 */
a {
  color: inherit;
  text-decoration: inherit;
}
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}
.slick-track:before,
.slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
/* Slider */
/* Icons */
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  margin-top: -10px \9;
  /*lte IE 8*/
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover,
.slick-next:hover,
.slick-prev:focus,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before,
.slick-next:hover:before,
.slick-prev:focus:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-prev {
  left: -25px;
}
.slick-prev[dir="rtl"] {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "←";
}
.slick-prev:before[dir="rtl"] {
  content: "→";
}
.slick-next {
  right: -25px;
}
.slick-next[dir="rtl"] {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "→";
}
.slick-next:before[dir="rtl"] {
  content: "←";
}
/* Dots */
.slick-slider {
  margin-bottom: 30px;
}
.slick-dots {
  position: absolute;
  bottom: -45px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}
/* 
 * COMPONENTS
 */
.accountDetails .nav-tabs {
  display: none;
}
.accountDetails__logo {
  display: none;
}
.mobile-edit-logo {
  display: none;
}
.mobile-logo-btn-delete {
  display: none;
}
.mobile-logo-btn-upload {
  display: none;
}
@media only screen and (max-width: 991px) {
  .edit-logo {
    display: none;
  }
  .mobile-edit-logo {
    display: block;
  }
  .mobile-edit-logo .inputGroup-textLabel {
    display: none;
  }
  .mobile-edit-logo .inputGroup {
    text-align: center;
  }
  .mobile-edit-logo img {
    border-radius: 50%;
    margin-bottom: -1.5em;
  }
  .desktop-logo-btn-upload {
    display: none !important;
  }
  .desktop-logo-btn-delete {
    display: none !important;
  }
  .mobile-logo-btn-delete {
    display: inline-block;
  }
  .mobile-logo-btn-upload {
    display: inline-block;
  }
  .accountDetails .shadowBox-headline,
  .accountDetails .sideTabs {
    display: none;
  }
  .accountDetails .nav-tabs {
    display: block !important;
  }
  .accountDetails.shadowBox {
    width: fit-content;
    display: block;
    margin: auto;
    box-shadow: none;
    background: none;
    padding: 0;
  }
  .nav.nav-tabs > li:last-of-type a {
    margin-right: 0;
  }
  .nav.nav-tabs > li:first-of-type a {
    margin-left: 0;
  }
  .accountDetails__logo {
    display: block;
  }
  .accountDetails__logo .keyValue-key {
    display: none;
  }
  .accountDetails__logo .keyValue-value {
    display: flex;
    justify-content: center;
  }
  .accountDetails__logo-bottom {
    display: none;
  }
  .accountEditForm {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
  }
  .accountEditForm .inputGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;
  }
  .accountEditForm .inputGroup #id_street {
    width: 95%;
  }
  .accountEditForm .inputGroup.addressFields {
    grid-column-start: span 2;
  }
  .accountEditForm .inputGroup.addressFields .inputGroup--inline {
    display: block;
  }
  .accountEditForm .inputGroup.addressFields .inputGroup--inline .inputGroup--sub {
    margin-top: 0;
    display: flex;
    width: 97.5%;
    justify-content: space-between;
  }
  .accountEditForm .inputGroup.addressFields .inputGroup--inline .inputGroup--sub .inputGroup--inline {
    width: 49%;
  }
  .accountEditForm .inputGroup--accountPostcode-textInput,
  .accountEditForm .inputGroup--accountCity-textInput {
    width: 95% !important;
  }
  .accountEditForm .inputGroup-textLabel {
    margin: 0.5em;
  }
  .accountEditForm .inputGroup-textInput {
    margin: 0.5em;
  }
  .accountEditForm .u-w250 {
    width: 90%;
    max-width: 100%;
  }
  .accountSettings .inputGroup-textLabel {
    margin-left: 0;
  }
  .accountSettings .inputGroup-input {
    margin: 0;
    margin-left: 0.5em;
  }
  .accountSettings .edit-submit {
    display: flex;
    flex-direction: column;
  }
  .accountSettings .edit-submit .hint {
    position: relative !important;
    transform: unset !important;
    top: 0;
  }
}
@media only screen and (max-width: 500px) {
  .mobileLoginWrapper {
    padding-right: 30px;
  }
  #id_street {
    width: 90% !important;
  }
  .accountEditForm {
    grid-template-columns: 1fr;
  }
  .accountEditForm .inputGroup.addressFields {
    grid-column: 1;
  }
  .accountEditForm .inputGroup.addressFields .inputGroup--inline .inputGroup--sub {
    width: 92%;
  }
  .modal-content,
  .modal-dialog {
    width: 101vw;
    height: 101vh;
    margin: 0;
    display: block;
  }
  .modal-content .inputGroup,
  .modal-dialog .inputGroup {
    display: flex;
    flex-direction: column;
  }
  .modal-content .inputGroup *,
  .modal-dialog .inputGroup * {
    margin: 0.25em 0 0.5em 0;
    width: 100%;
  }
  .nav-tabs > li > a {
    margin-left: 0 !important;
    padding: 1px 5px;
  }
  .edit-submit {
    display: flex;
    flex-direction: column;
  }
  .edit-submit .hint {
    position: relative !important;
    transform: unset !important;
    top: 0;
  }
}
@media only screen and (max-width: 325px) {
  .nav-tabs > li > a {
    margin-right: 0 !important;
    padding: 1px;
  }
}
.additionalInformation {
  position: absolute;
  bottom: -20px;
  right: 0;
  padding-bottom: 30px;
  margin-right: 15px;
  z-index: 1000;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px;
  cursor: pointer;
}
.needsSpecTradeFair {
  cursor: pointer;
}
.additionalInformation-tooltip {
  margin-left: -100px;
  display: none;
  position: absolute;
  right: 0;
  bottom: -90px;
  padding-bottom: 20px;
  background-color: #547a8b;
  width: 250px;
  height: 100px;
  max-width: unset !important;
}
.needsSpecTradeFair-tooltip {
  margin-left: -100px;
  display: none;
  position: absolute;
  left: 0;
  bottom: -10px;
  padding-bottom: 20px;
  background-color: #547a8b;
  width: 250px;
  height: 100px;
  z-index: 5;
}
.additionalInformation-tooltip:after,
.needsSpecTradeFair-tooltip:after {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 0 15px 15px;
  border-color: #547a8b transparent;
  display: block;
  width: 0;
  z-index: 1;
  top: -15px;
  left: 110px;
}
.additionalInformation-textarea,
.needsSpecTradeFair-textarea {
  padding: 3px 6px;
  background-color: #547a8b;
  border-color: #ffffff;
  color: #ffffff;
  height: 90px;
  width: 230px;
  margin-left: 10px;
  margin-top: 5px;
  resize: none;
}
.additionalInformation-textarea::-webkit-input-placeholder,
.needsSpecTradeFair-textarea::-webkit-input-placeholder {
  color: #ffffff !important;
}
.additionalInformation-textarea::-moz-placeholder,
.needsSpecTradeFair-textarea::-moz-placeholder {
  color: #ffffff !important;
}
.additionalInformation-textarea:-moz-placeholder,
.needsSpecTradeFair-textarea:-moz-placeholder {
  color: #ffffff !important;
}
.additionalInformation-textarea:-ms-input-placeholder,
.needsSpecTradeFair:-ms-input-placeholder {
  color: #ffffff !important;
}
.additionalInformation-textarea:focus,
.needsSpecTradeFair-textarea:focus {
  outline: 0;
}
.additionalInformation-content,
.needsSpecTradeFair-content {
  display: inline-block;
  padding-left: 10px;
  padding-top: 5px;
  color: #ffffff;
  white-space: pre-wrap;
}
.additionalInformation-edit,
.needsSpecTradeFair-edit {
  text-decoration: underline;
  position: absolute;
  bottom: 5px;
  right: 10px;
  color: #ffffff;
}
@media only screen and (max-width: 500px) {
  .additionalInformation-tooltip {
    left: -44px;
    margin-left: 0;
  }
}
.autocomplete-suggestions {
  border: 0;
  -webkit-box-shadow: 0 5px 20px 2px #bbb;
  -moz-box-shadow: 0 5px 20px 2px #bbb;
  box-shadow: 0 5px 20px 2px #bbb;
  padding-bottom: 10px;
  position: absolute;
  background-color: #ffffff;
  z-index: 10000;
}
.autocomplete-suggestion {
  font-family: "Humanist Condensed Light";
  padding-left: 15px;
}
.autocomplete-category {
  margin-top: 10px;
  padding-left: 15px;
  font-family: "Humanist Condensed Bold";
  color: #a2a19f;
}
.autocomplete-suggestion b {
  color: #000000;
  font-family: "Humanist Condensed Bold";
}
.address-label,
.address-value {
  display: inline-block;
}
.address-label {
  width: 60px;
}
.address-field {
  margin-bottom: 20px;
}
.address-title {
  font-size: 1.9em;
}
.address-headline {
  font-size: 1.45em;
}
.js-needsSpec .select2 {
  width: 150px !important;
}
.tradeFairLineInfo-warning {
  border: 1px solid #b0053a;
  color: #b0053a;
}
.mobile-basket-table,
.mobile-shipppig-icon,
.mobile-basket-link {
  display: none;
}
.mobile-amount-btn {
  background: none;
  border: none;
  padding: 0;
}
.mobile-basket-amount-input-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.mobile-basket-amount-input-wrapper > * {
  margin-right: 5px !important;
}
.mobile-basket-amount-input-wrapper .js-quantity {
  margin-top: 0;
}
@media (max-width: 1220px) {
  .basketActionButtons {
    margin-bottom: 20px;
  }
}
@media (max-width: 1040px) {
  .basketActionButtons {
    margin-bottom: 40px;
  }
}
@media only screen and (min-width: 320px) {
  .mobile-basket-link {
    left: 79vw;
  }
}
@media only screen and (min-width: 365px) {
  .mobile-basket-link {
    left: 80vw;
  }
}
@media only screen and (min-width: 425px) {
  .mobile-basket-link {
    left: 84vw;
  }
}
@media only screen and (min-width: 500px) {
  .mobile-basket-link {
    left: 89vw;
  }
}
@media only screen and (max-width: 991px) {
  .js-cart.cart {
    display: none !important;
  }
  .mobile-basket-link {
    position: fixed;
    top: 70vh;
    z-index: 100001;
    display: block;
  }
  .mobile-basket-link a {
    display: inline-block;
    background: #ffffff;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    border: none;
    padding: 1em;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  .mobile-basket-link a svg {
    margin-top: 4px;
  }
  .mobile-basket-link .cartQuantity-mobile {
    position: absolute;
    right: -5px;
    top: -5px;
    color: #ffffff;
    background: #547a8b;
    padding: 0.2em;
    border-radius: 50%;
    height: 26px;
    width: 26px;
    text-align: center;
  }
  .mobile-basket-link .basketNotification {
    top: 77vh;
    right: 7em;
    padding: 10px 30px;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
  }
  .basket-order-another {
    display: none;
  }
  .basketButtons {
    display: none;
  }
  .basket-group-wrapper {
    width: 95%;
    margin: auto;
    margin-top: 1em;
  }
  .basket-group-wrapper .inputGroup > *,
  .basket-group-wrapper .inputGroup-textLabel {
    margin-bottom: 0;
    margin-top: 0.2em;
  }
  .basket-group-wrapper .u-warning {
    display: block;
  }
  .basketForm {
    max-width: 90%;
    margin: auto;
  }
  .desktop-basket-wrapper {
    display: none;
  }
  .table-row--basket {
    display: none;
  }
  .table-row--basket-mobile {
    display: block;
  }
  .table.dataTable {
    display: none;
  }
  .inputGroup.pull-left {
    float: none !important;
  }
  .js-price-mobile {
    font-size: 18px;
  }
  .mobile-basket-table {
    display: block;
    margin-top: 1em;
    border-top: 1px solid #d5d4d3;
  }
  .mobile-basket-table .mobile-basket-item:nth-child(odd) {
    background-color: #eaeae9;
  }
  .mobile-basket-table .mobile-basket-item-last {
    border-bottom: 1px solid black;
  }
  .mobile-basket-table .order-another {
    display: block;
    text-align: center;
    font-size: 18px;
    width: 100%;
    padding: 0.8em 0;
  }
  .mobile-basket-item {
    border-top: 1px solid black;
    padding: 1em;
    display: grid;
    grid-template-columns: 0.3fr 1fr 0.5fr 0.3fr;
    grid-template-areas: "image details1 price delete" "image details2 price delete" "placeholder details3 expand expand" "placeholder extra extra extra";
    grid-column-gap: 0.5em;
  }
  .mobile-basket-item .mobile-basket-item-image {
    grid-area: image;
  }
  .mobile-basket-item .mobile-basket-item-image img {
    width: 100%;
  }
  .mobile-basket-item .mobile-basket-title {
    grid-area: details1;
  }
  .mobile-basket-item .mobile-single-price {
    grid-area: details2;
    font-weight: bold;
  }
  .mobile-basket-item .mobile-price {
    grid-area: price;
    text-align: right;
    font-weight: bold;
  }
  .mobile-basket-item .mobile-price::after,
  .mobile-basket-item .mobile-single-price::after {
    content: "€";
    font-family: 'Arial, sans-serif';
  }
  .mobile-basket-item .mobile-basket-amount {
    grid-area: details3;
  }
  .mobile-basket-item .mobile-basket-amount span {
    display: block;
  }
  .mobile-basket-item .mobile-basket-amount .mobile-basket-amount-input-wrapper .inputGroup-textInput {
    margin: 0 0.2em;
  }
  .mobile-basket-item .mobile-basket-expand {
    grid-area: expand;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: right;
  }
  .mobile-basket-item .mobile-basket-details {
    grid-area: extra;
    border-top: 1px solid #8e8d8e;
    padding-top: 0.5em;
    max-width: 100%;
  }
  .mobile-basket-item .mobile-basket-details div {
    margin-top: 0.2em;
    max-width: 100%;
  }
  .mobile-basket-item .mobile-basket-details .productSpec-inputGroup-input {
    width: 75%;
  }
  .mobile-basket-item .mobile-basket-details .inputGroup-basket--city,
  .mobile-basket-item .mobile-basket-details .select2.select2-container {
    max-width: 100%;
  }
  .mobile-basket-item .mobile-basket-details .inputGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.2em;
  }
  .mobile-basket-item .mobile-basket-details .additionalInformation {
    position: relative;
    padding: 0 0 0.5em 0;
    margin: 0;
  }
  .panel-group:last-of-type {
    margin-bottom: 0.5em;
  }
  .panel-group:last-of-type .basketNetPriceDescription {
    border-bottom: 1px solid black;
    padding: 1em 0;
  }
}
@media only screen and (max-width: 620px) {
  .desktop-shipping-icon {
    display: none;
  }
  .mobile-shipping-icon {
    display: inline;
    margin-left: 0;
  }
  .basket-orderNumber {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .basket-orderNumber label {
    margin-bottom: 0;
    display: block;
  }
  .basket-orderNumber input.inputGroup-textInput {
    width: 100%;
  }
  .basket-addressSelect {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .basket-addressSelect .u-inlineBlock {
    display: block;
  }
  .basket-addressSelect label {
    margin-bottom: 0;
    margin-top: 0;
  }
  .basket-addressSelect .select2.select2-container {
    width: 100% !important;
  }
}
@media only screen and (max-width: 500px) {
  .basket-top-filters {
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .basket-top-filters .commonFilter {
    height: unset;
    line-height: unset;
    margin-top: 0.3em;
  }
  .basketActionButtons {
    margin-top: 0.9em;
  }
  .basketActionButtons button {
    width: 100%;
  }
  .mobile-basket-link .basketNotification {
    max-width: 75%;
    padding-right: 5px;
  }
  .mobile-basket-link .basketNotification img {
    display: none;
  }
  .mobile-basket-link .basketNotification .u-small {
    display: none;
  }
}
@media only screen and (max-width: 991px) {
  .estimate-tabbed-view {
    width: 100vw;
  }
  .estimate-tabbed-view > .u-marginTop30 {
    width: 100%;
  }
  .basketForm .tabbed-view-filters {
    display: flex !important;
  }
  .tabbed-view-wrapper.u-marginTop30 {
    margin-top: 0;
    float: none !important;
  }
  .tabbed-view-wrapper.u-marginTop30 input {
    margin-top: 0;
    margin-bottom: 0.2em;
  }
  .basketForm .tabbed-view-filters {
    display: flex !important;
    flex-wrap: wrap;
  }
  .tabbed-view-filters {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .tabbed-view-filters a {
    flex: 1 0 auto;
    text-align: center;
    height: unset;
    line-height: unset;
    margin: 0.3em 0 0 0;
    width: 48%;
  }
  .tabbed-view-filters a:first-child {
    width: 100%;
  }
  .tabbed-view-filters a:nth-child(2) {
    margin-right: 5px;
  }
  .tabbed-view-filters a:nth-child(3) {
    margin-left: 5px;
  }
  .tabbed-view-filters form {
    width: 100%;
  }
}
.brandFilter {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.filter-brand--item {
  text-align: center;
  background-color: #ffffff;
}
.filter-brand--item.is-inactive {
  border-width: 0;
  cursor: default;
  padding: 15px 8px 18px;
}
.filter-brand--label {
  width: 100%;
  cursor: pointer;
  padding: 15px 8px 18px;
  margin-bottom: 0;
}
.filter-brand--label:hover,
.filter-brand--item.is-active .filter-brand--label {
  border-bottom-style: solid;
  border-width: 3px;
  padding-bottom: 15px;
}
.filter-brand--image {
  height: 17px;
  width: auto;
  pointer-events: none;
}
@media only screen and (max-width: 991px) {
  .specificationFilter .brandFilter {
    display: flex;
  }
}
@media (max-width: 1200px) {
  .brandFilter {
    flex-wrap: wrap;
  }
  .filter-brand--item {
    flex: 1 0 13%;
  }
}
.basketNotification {
  position: fixed;
  top: 105px;
  right: 16%;
  width: 350px;
  background-color: #fff;
  display: none;
  z-index: 10101;
  font-size: 0.9em;
}
.basketNotification-headline {
  margin-top: 10px;
  color: #a2a19f;
}
.basketNotfication-overlayMenu {
  position: fixed;
  top: 38px;
  right: 15.27777%;
  padding-top: 15px;
  padding-left: 14px;
  padding-right: 14px;
  padding-bottom: 15px;
  z-index: 10000;
  font-size: 1.2em;
  text-decoration: none;
}
.basketNotification-line {
  min-height: 40px;
}
@media (min-width: 1181px) {
  .basketNotification {
    top: 95px;
  }
}
/*
 * Breadcrumbs
 */
.breadcrumbs-container {
  background-color: #d5d4d3;
  text-align: center;
  margin-right: -10px;
  margin-left: -10px;
  margin-top: 5px;
}
.breadcrumbs {
  background-color: #ffffff;
  display: inline-block;
  list-style: none;
  margin-bottom: 0;
  padding: 0;
}
.breadcrumb {
  color: #a2a19f;
  background-color: #d5d4d3;
  display: inline-block;
  position: relative;
  padding: 10px;
  padding-left: 60px;
  padding-right: 40px;
  margin-right: 10px;
  margin-bottom: 0;
}
.breadcrumb.is-active {
  background-color: #a2a19f;
  color: #ffffff;
}
.breadcrumb:first-child:before,
.breadcrumb:last-child:after {
  border: 0;
}
.breadcrumb:last-child {
  margin-right: 0;
}
.breadcrumb:before {
  content: "";
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid #ffffff;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
.breadcrumb:after {
  content: "";
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid #d5d4d3;
  position: absolute;
  right: -20px;
  top: 0;
  z-index: 2;
}
.breadcrumb.is-active:after {
  border-left: 20px solid #a2a19f;
}
.breadcrumbsContainer--standard {
  background-color: #d5d4d3;
}
.breadcrumbs--standard {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  color: #8e8d8e;
  font-size: 0.9em;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
.breadcrumbs-breadcrumb--standard {
  display: inline-block;
  margin-right: 10px;
}
.breadcrumbs-breadcrumb--standard:not(:last-child):after {
  content: '>';
  display: inline-block;
  margin-left: 10px;
}
.breadcrumbs-breadcrumb--standard.is-active {
  font-family: "Humanist Condensed Bold";
}
@media (max-width: 1200px) {
  .breadcrumb {
    padding-left: 35px;
    padding-right: 15px;
    margin-right: 4px;
  }
}
.breadcrumbs-container--mobile {
  display: none;
}
@media only screen and (max-width: 991px) {
  .breadcrumbs-wrapper {
    padding: 0;
  }
  .breadcrumbs-container {
    display: none;
  }
  .breadcrumbs-container--mobile {
    display: block;
  }
  .breadcrumbs-container--mobile .breadcrumbs {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .breadcrumbs-container--mobile .breadcrumbs .breadcrumb {
    height: 40px;
    min-width: 5%;
    max-width: 10%;
    width: 500px;
  }
  .breadcrumbs-container--mobile .breadcrumbs .breadcrumb span {
    display: none;
  }
  .breadcrumbs-container--mobile .breadcrumbs .breadcrumb.is-active {
    width: 500px;
    min-width: 20%;
    max-width: 70%;
    white-space: nowrap;
  }
  .breadcrumbs-container--mobile .breadcrumbs .breadcrumb.is-active span {
    display: inline;
  }
}
@media only screen and (max-width: 353px) {
  .breadcrumb.is-active span {
    display: none !important;
  }
}
/* 
 * This file contains all buttons 
 *
 * .btn - base class for Button component
*/
.btn {
  border-width: 2px;
  border-style: solid;
  box-sizing: border-box;
  display: inline-block;
  font-size: 1em;
  font-family: "Humanist Condensed Bold";
  margin: 10px 0;
  padding: 6px 12px;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
}
.btn[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
.btn .ofa-icon {
  display: inline-block;
  font-size: 19px;
  margin-top: -6px;
  margin-bottom: -2px;
  margin-right: 4px;
}
.btn .ofa-glyphicon {
  display: inline-block;
  margin-top: -6px;
  margin-bottom: -2px;
  margin-right: 10px;
}
.btn:focus {
  outline: 0;
}
.btn--icon {
  padding: 5px 5px 3px;
  line-height: 1em;
  font-size: 12px;
}
.btn--newsletter {
  height: 26px;
  width: 28px;
  padding: 0;
  margin: 0;
  margin-left: 2px;
}
.btn--newsletter .ofa-icon {
  display: inline-block;
  font-size: 17px;
  margin-top: -1px;
  margin-left: 4px;
}
/*
 * Primary button
 */
.btn--primary {
  background-color: #a2a19f;
  border-color: #a2a19f;
  color: #ffffff;
}
.btn--primary[class^="icon-"] {
  margin-right: 10px;
}
.btn--primary:hover {
  border-color: #8e8d8e;
  background-color: #8e8d8e;
}
.btn--primary[disabled]:hover {
  background-color: #a2a19f;
  border-color: #a2a19f;
}
/* Primary button is available in different colors */
.btn--primaryBlue {
  background-color: #547a8b;
  border-color: #547a8b;
  color: #ffffff;
}
.btn--primaryBlue:hover {
  border-color: #346274;
  background-color: #346274;
}
.btn--primaryBlue[disabled]:hover {
  background-color: #547a8b;
  border-color: #547a8b;
}
.btn--primaryBlack {
  background-color: #000000;
  border-color: #000000;
  color: #ffffff;
}
.btn--primaryBlack:hover {
  background-color: #000000;
  border-color: #000000;
}
.btn--primaryBlack[disabled]:hover {
  background-color: #000000;
  border-color: #000000;
}
/*
 * Secondary button
 */
.btn--secondary {
  background-color: #ffffff;
  border-color: #a2a19f;
  color: #a2a19f;
}
.btn--secondary:hover,
.btn--secondary:focus,
.btn--secondary:focus-visible,
.btn--secondary:active {
  border-color: #8e8d8e;
  color: #8e8d8e;
  outline: 0 !important;
}
.btn--secondary[disabled]:hover {
  background-color: #ffffff;
  border-color: #a2a19f;
}
.btn--third,
.btn--third[disabled]:hover {
  background-color: #134b5c;
  border-color: #134b5c;
  color: #ffffff;
}
.btn--third:hover,
.btn--third:focus,
.btn--third:focus-visible,
.btn--third:active {
  border-color: #033343;
  background-color: #033343;
  color: #ffffff;
  outline: 0 !important;
}
/*
 * Warning Button
 */
.btn--warning {
  background-color: #b0053a;
  border-color: #b0053a;
  color: #ffffff;
}
.btn--warning:hover {
  border-color: #7e042a;
  background-color: #7e042a;
}
/*
 * Link button
 */
.btn--link {
  border: none;
  font-family: "Humanist Condensed Italic";
  color: #a2a19f;
  text-decoration: underline;
  margin: 0;
  cursor: pointer;
}
.btn--primary--noClick:hover {
  background-color: #a2a19f !important;
  border-color: #a2a19f !important;
  cursor: default;
}
@media (max-width: 1040px) {
  .btn {
    padding: 4px 8px;
  }
  .btn .ofa-icon {
    margin-right: 0;
  }
  .btn--newsletter .ofa-icon {
    margin-top: -3px;
    margin-left: -3px;
  }
  .btn--newsletter .ofa-icon:before {
    margin-top: -7px;
  }
}
/* Categories */
.categoryItem label {
  cursor: pointer;
  font-weight: normal;
}
/* Filters */
.filter-headline {
  cursor: pointer;
}
.filter-headline:after {
  font-family: "Glyphicons Halflings";
  content: "\e080";
  float: right;
  font-size: 0.8em;
  line-height: 21px;
  color: #a2a19f;
}
.filter-headline[aria-expanded="true"]:after {
  content: "\e114";
}
.filter-headline.filter-set:after {
  display: none;
}
.filter-label {
  display: block;
  font-weight: normal;
  cursor: pointer;
  padding: 2px 5px;
  transition: all 0.1s ease-in-out;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  width: 130px;
  padding-right: 10px;
}
.filter-label:hover {
  transition: all 0.1s ease-in-out;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  background-color: #d5d4d3;
}
.filter-label.is-active {
  border: solid 1px #000000;
}
.filter-label.is-active:after {
  content: "\54";
  font-family: "ofa-icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.filter-label.is-active:after {
  /* Set the remove-glyphicon */
  line-height: 21px;
  font-size: 10px;
  float: right;
  color: #000000;
}
.filter-input {
  display: none;
}
.filter-deleteAll {
  /* The sidebar has a padding of 10px 30px. This class makes childs "fullwidth" in the sidebar */
  margin-left: -30px;
  margin-right: -30px;
  margin-bottom: -10px;
  /* To get rid of the sidebars padding */
  margin-top: 20px;
  padding: 20px;
  text-align: center;
  color: #ffffff;
  background-color: #a2a19f;
  font-weight: bold;
  cursor: pointer;
}
.filter-deleteAll .glyphicon {
  margin-right: 5px;
}
/*
 *Specific filter templates
 */
/* size, bodypart, sizeorthopaedic */
.filter-listItemWithIcon img {
  height: 20px;
  margin-right: 10px;
}
/* colorfilter */
.filter-color--item {
  display: inline-block;
  text-align: center;
  width: 35px;
}
.filter-color--preview {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-bottom: -6px;
  margin-left: auto;
  margin-right: auto;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  position: relative;
}
.filter-background {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-top: 1px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #a2a19f;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.filter-color--preview.is-active {
  border: 2px solid #a2a19f;
  width: 29px;
  height: 29px;
  margin-bottom: -3px;
}
.filter-color--preview.is-active .filter-background {
  width: 23px;
  height: 23px;
}
.filter-color--preview.is-active:hover .filter-color-closeButton {
  display: inline-block;
  height: 13px;
  width: 13px;
  position: absolute;
  right: -3px;
  top: -3px;
  background-color: #b0053a;
  color: white;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.filter-color--preview.is-active:hover .filter-color-closeButton:after {
  content: "\54";
  font-family: "ofa-icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.filter-color--preview.is-active:hover .filter-color-closeButton:after {
  position: absolute;
  top: 1px;
  left: 4px;
  font-size: 5px;
  line-height: 12px;
}
.filter-color--item label {
  display: inline-block;
  cursor: pointer;
  font-weight: normal;
}
/* griptopfilter */
.filter-griptop--item {
  display: inline-block;
  text-align: center;
  width: 49%;
}
.filter-griptop--image {
  width: 45px;
  height: 45px;
  border-radius: 100%;
}
.filter-griptop--image.is-active {
  border: 2px solid #a2a19f;
}
.filter-griptop--item label {
  cursor: pointer;
  font-weight: normal;
}
/* variationfilter */
.filter-variation--item {
  display: inline-block;
  text-align: center;
  width: 23%;
  /* There are 4 variants */
  padding-top: 8px;
}
.filter-variation--image {
  width: 35px;
  height: 55px;
}
.filter-variation--item.is-active {
  border: 2px solid #a2a19f;
}
.filter-variation--item label {
  cursor: pointer;
  font-weight: normal;
}
.filter-serial-size,
.filter-custom-size,
.filter-child-size {
  margin-right: 10px;
}
.filter-serial-size:before,
.filter-serial-made:before,
.filter-seriengroe:before {
  content: "\58";
}
.filter-custom-size:before,
.filter-made-to-measure:before,
.filter-magroe:before {
  content: "\57";
}
.filter-child-size:before,
.filter-also-for-children:before,
.filter-kindergroe:before {
  content: "\5A";
  font-size: 22px;
  margin-left: -8px;
}
.filter-kleine-groen:before {
  content: "\5A";
  font-size: 22px;
  margin-left: -8px;
}
/* selectorfilter and footfilter are default checkboxes */
/* Utilities */
.sidebar-fullwidthChild {
  /* The sidebar has a padding of 10px 30px. This class makes childs "fullwidth" in the sidebar */
  margin-left: -30px;
  margin-right: -30px;
}
.colorImage {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  margin-right: 3px;
  width: 30px;
  height: 30px;
}
.commonFilter {
  color: #547a8b;
  border-radius: 17px;
  height: 34px;
  line-height: 34px;
  font-size: 16px;
  margin-right: 15px;
  border: 2px solid #547a8b;
  padding: 3px 10px;
}
.commonFilter.is-active {
  background-color: #547a8b;
  color: #ffffff;
}
.checkbox-filter-spacer {
  margin-bottom: 2em;
}
.employeePortrait {
  height: 280px;
}
.employeePortrait-imageContainer {
  height: 180px;
  position: relative;
}
.employeePortrait-imageContainer img {
  position: absolute;
  bottom: 0;
}
.employeePortrait-position {
  font-weight: bold;
}
@media only screen and (max-width: 991px) {
  .contact-heading {
    text-align: center;
  }
  .contact-form {
    max-width: 60%;
    margin: auto;
  }
  .contact-form .inputGroup .inputGroup-textLabel {
    margin-top: 0;
    margin-bottom: 0;
  }
  .contact-form .inputGroup .inputGroup-textLabel.pull-left {
    float: none !important;
  }
  .contact-form .u-w300 {
    max-width: 100%;
    width: 100%;
  }
  .contact-image-wrapper {
    display: none;
  }
}
@media only screen and (max-width: 500px) {
  .contact-form {
    max-width: 100%;
  }
}
.discount-errors {
  color: #b0053a;
}
.discount-info {
  color: #2a7216;
}
.discount-icon {
  color: #2a7216;
}
/*
 * Styles for hints
 */
.hint {
  font-size: 0.9em;
  font-family: "Humanist Condensed Italic";
}
.flatknittingTable-head {
  text-align: center;
}
.flatknittingTable-data input {
  width: 50px;
  margin: 5px;
}
.fingerNumber {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  display: inline-block;
  border: 1px solid #000000;
  margin-right: 10px;
}
.countdownContainer {
  margin-top: 40px;
}
.countdown {
  -webkit-box-shadow: 0 0 20px 2px #bbb;
  -moz-box-shadow: 0 0 20px 2px #bbb;
  box-shadow: 0 0 20px 2px #bbb;
  padding: 10px 15px;
  display: inline-block;
  text-align: center;
  width: auto;
  margin-right: 15px;
  color: #a2a19f;
  font-size: 1.9em;
  background-color: rgba(255, 255, 255, 0.9);
}
.countdown-label {
  font-family: "Humanist Condensed Light";
  line-height: 10px;
  font-size: 1.1em;
}
.countdown-value {
  font-size: 2.1em;
  font-family: "Humanist Condensed Bold";
  line-height: 1em;
}
.topBar {
  background-color: white;
  -webkit-box-shadow: 0 0 20px 2px #bbb;
  -moz-box-shadow: 0 0 20px 2px #bbb;
  box-shadow: 0 0 20px 2px #bbb;
}
.topBar-logo {
  height: 60px;
}
.splashFooter {
  position: absolute;
  bottom: 0;
  margin-right: -10px;
  margin-left: -10px;
  width: 100%;
  height: 60px;
  background-color: #eaeae9;
  color: #8e8d8e;
  line-height: 60px;
}
/* PUSH FOOTER */
/* These classes are used to guarantee that the footer is always at the bottom of the page*/
.main-container {
  position: relative;
}
.content {
  padding-bottom: 460px;
}
/* END FOOTER PUSH */
.footer {
  margin-top: 50px;
  background-color: #d5d4d3;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.footer-linkList {
  list-style: none;
  padding: 0;
  margin: 0;
  padding-bottom: 40px;
}
.footer-category {
  padding-top: 20px;
  font-size: 1.27em;
}
.footer-lowerPart {
  padding-bottom: 50px;
  padding-top: 10px;
  border-top: 1px solid #000000;
}
.footer-lowerPart-links {
  text-align: right;
}
.footer-lowerLink {
  margin-left: 70px;
}
.footer-forum-link {
  display: block;
  font-size: 1.27em;
  margin-top: 10px;
}
@media only screen and (max-width: 991px) {
  .content {
    padding-bottom: 0;
  }
  .footer {
    position: relative;
    width: 100vw;
  }
  .footer .row:first-of-type {
    max-width: 100%;
    margin: auto;
    padding-top: 1em;
  }
  .footer-lowerPart-links {
    text-align: center;
  }
  .footer-lowerLink {
    margin-left: 1em;
  }
}
@media only screen and (max-width: 768px) {
  .clear-left {
    clear: left;
  }
}
@media only screen and (max-width: 500px) {
  .footer-category {
    margin-top: 0;
    padding-top: 0;
  }
  .footer-category:last-of-type {
    padding-top: 1em;
  }
  .footer-lowerPart-links {
    margin-top: 1em;
  }
}
.formattedContent a {
  color: #547a8b;
  text-decoration: underline;
}
.frontpageHeader-fullWidth {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  top: -10px;
  position: relative;
}
.frontpage-tile {
  padding-top: 20px;
  padding-bottom: 20px;
}
.frontpage-tile--heading {
  font-size: 1.27em;
  color: #a2a19f;
  font-family: "Humanist Condensed Bold";
}
.frontpage-tile--heading + .dataTables_wrapper {
  margin-bottom: 15px;
}
.frontpage-shadowBox {
  box-sizing: border-box;
  display: inline-block;
  padding: 10px 30px;
  -webkit-box-shadow: 0 0 20px 2px #bbb;
  -moz-box-shadow: 0 0 20px 2px #bbb;
  box-shadow: 0 0 20px 2px #bbb;
  background-color: white;
  position: relative;
  width: 100%;
  padding: 15px 30px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
}
.frontpage-tile--description {
  word-wrap: break-word;
  margin-top: 20px;
}
.frontpageHeader-container {
  margin: 0;
  -webkit-transform: translate(0, 8%);
  -moz-transform: translate(0, 8%);
  -ms-transform: translate(0, 8%);
  -o-transform: translate(0, 8%);
  margin-bottom: 95px;
}
.frontpage-imageContainer {
  margin-left: -30px;
  margin-right: -30px;
  height: 210px;
  text-align: center;
}
.frontpage-tile--image {
  max-height: 215px;
  width: auto;
  max-width: 100%;
}
.frontpage-headline {
  height: 75px;
}
.frontpage-shadowBox-news {
  border-bottom: 7px solid;
}
/* Static tiles */
.frontpage-imageTile--image {
  background-image: url('../images/fachkunden.jpg');
  height: calc(100% + 20px);
  display: inline-block;
  width: 55%;
  margin-left: -30px;
  margin-top: -10px;
  margin-bottom: -10px;
  background-size: cover;
}
/* This is just for the font color of the frontpage header content. We have a button here, just out of convenience, */
/* so that we can easily edit the color at one location */
.btn--primaryFrontpageHeader {
  border-color: #d5d4d3;
  color: #d5d4d3;
}
.btn--primaryFrontpageHeader:hover {
  border-color: #8e8d8e;
  color: #8e8d8e;
}
.btn--primaryFrontpageHeader[disabled]:hover {
  background-color: #d5d4d3;
  border-color: #d5d4d3;
}
.frontpageHeader-text {
  color: #d5d4d3;
}
.frontpage-description {
  margin-bottom: 15px;
}
.frontpage .brandFilter {
  display: flex;
}
.frontpage .brandFilter .filter-brand--item {
  flex-basis: 26%;
}
@media (min-width: 768px) {
  .frontpage-tile {
    padding: 20px;
  }
  .frontpage-shadowBox {
    padding: 10px 30px;
  }
  .frontpage-description {
    margin-bottom: 0;
  }
  .frontpage-tile--heading + .dataTables_wrapper {
    margin-bottom: 30px;
  }
  .frontpage-tile--footer {
    position: absolute;
    bottom: 10px;
  }
  .frontpage-upperBox {
    height: 265px;
  }
  .frontpageHeader-container {
    margin-bottom: 50px;
  }
  .frontpage .brandFilter .filter-brand--item {
    flex-basis: 18%;
  }
}
@media (min-width: 992px) {
  .frontpage .brandFilter .filter-brand--item {
    flex-basis: 13%;
  }
}
@media only screen and (max-width: 991px) {
  .frontpageHeader-fullWidth {
    top: 0;
  }
}
.gallerySlider {
  max-height: 400px;
}
.gallerySlider-slide {
  text-align: center;
}
.gallerySlider-slide img {
  width: auto;
  height: auto;
  max-height: 400px;
  display: inline-block;
  max-width: 100%;
}
.gallerySlider--nav-slide {
  height: 100px !important;
  text-align: center;
}
.gallerySlider--nav {
  height: 100px;
  margin-top: 30px;
}
.gallerySlider--nav img {
  margin: auto 2%;
  cursor: pointer;
  height: auto;
  max-height: 100px;
  display: inline-block;
  max-width: 100%;
}
.gallerySlider--nav img:focus {
  outline: 0;
}
.slick-list,
.slick-track {
  height: 100%;
}
.slick-arrow {
  position: absolute;
  top: 35px;
  background-color: transparent;
  border: 0;
  z-index: 10;
}
.slick-arrow:focus {
  outline: 0;
}
.slick-next {
  right: 0;
}
.slick-slide {
  width: 100%;
  height: auto;
}
@media print {
  .gallerySlider {
    display: none;
  }
}
@media (max-width: 1040px) {
  .gallerySlider--nav {
    margin-top: 10px;
  }
}
/*
 * Horizontal lines
 */
.hr {
  margin-top: 10px;
  margin-bottom: 10px;
  border-top: 1px solid #000000;
  margin-left: 0;
}
.hr--brandLight {
  border-top: 1px solid #d5d4d3;
}
.hr--brandNeutral {
  border-top: 1px solid #a2a19f;
}
.hr--login {
  margin-top: 20px;
  margin-bottom: 20px;
  border-top: 1px solid #a2a19f;
}
.hr-w300 {
  max-width: 300px;
  width: 100%;
}
.hr-w400 {
  width: 400px;
}
.hr-w450 {
  width: 100%;
  max-width: 450px;
}
.hoverCart {
  position: fixed;
  right: 16%;
  width: 350px;
  background-color: #fff;
  display: none;
}
.cart:hover .hoverCart {
  display: block;
}
.hoverCart--noFHP {
  right: 10%;
}
.hoverSearch {
  position: fixed;
  right: 8.88888%;
  background-color: #fff;
  display: none;
}
.generalsearch:hover .hoverSearch,
.generalsearch.hover .hoverSearch {
  display: block;
}
.hoverSearch--noFHP {
  right: 4%;
}
@media only screen and (max-width: 991px) {
  .searchHeading {
    padding-left: 10px;
  }
  .searchResultTabs {
    display: none;
  }
  .tab-content .tile--product-right img {
    width: auto;
    height: auto;
    max-height: 100%;
    max-width: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .dataTables_empty.u-paddingTop10 {
    max-width: 100px;
    white-space: break-spaces;
  }
}
body .main-container > .infosite {
  font-size: 1.27em;
}
.infosite a:not([class*="btn"]) {
  color: #547a8b;
  text-decoration: none;
}
.infosite a:hover:not([class*="btn"]) {
  color: #547a8b;
}
.infosite a.redHover:hover {
  color: #000000;
}
.infosite ul:not([class]) > li,
.infosite .cms-plugin > li {
  list-style: none;
}
.infosite ul:not([class]) > li:before,
.infosite .cms-plugin > li:before {
  content: "\2013\a0";
  margin-left: -17px;
}
/*
 * Input fields2
 *
 * This file contains all styles for inputs including text, number, radio, checkbox and dropdown.
 * It also contains all styles for labels.
 */
label {
  font-weight: normal;
  /* Override bootstraps label font-weight: bold; */
}
.inputGroup {
  display: block;
}
.inputGroup--inline {
  display: inline-block;
}
.inputGroup--sub {
  margin-top: -30px;
}
.inputGroup-headline {
  font-size: 1.27em;
  color: #a2a19f;
  font-family: "Humanist Condensed Bold";
}
.inputGroup-numberInput,
.inputGroup-textInput,
.inputGroup-textArea {
  background-color: #ffffff;
  border-width: 1px;
  border-color: #a2a19f;
  border-style: solid;
  color: #000000;
  display: inline-block;
  font-family: "Humanist Condensed Light";
  font-size: 1em;
  padding: 3px 6px;
  margin: 18px 0;
  /* 18px = 20px - 1px (border) -1px (padding) */
  line-height: 16px;
}
.inputGroup-numberInput,
.inputGroup-textInput {
  height: 26px;
  width: 200px;
}
.inputGroup-textArea {
  height: 8em;
  width: 90%;
}
.u-patientForm .inputGroup-textInput,
.u-patientForm .inputGroup-numberInput {
  width: 300px;
}
.inputGroup-numberInput {
  width: auto;
}
.inputGroup--firstName-input {
  width: 166px !important;
  /* Patient Create View */
}
.inputGroup--birthday-input {
  width: 40px !important;
  margin-right: 0 !important;
}
/* Patient Create View */
.inputGroup--birthyear-input {
  width: 106px !important;
  margin-right: 0 !important;
}
.inputGroup-textInput--noMargin,
.inputGroup-textInput--noMargin:focus {
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-top: 0 !important;
}
.inputGroup-textInput--autoWidth {
  width: auto;
}
.inputGroup-textInput--newsletter {
  width: 140px;
  height: 26px;
  vertical-align: top;
  border: 0;
  margin: 0;
}
.inputGroup--postcode-textInput {
  margin-right: 0 !important;
  width: 130px !important;
  /* IMPORTANT! Note this is dependent on the width of inputGroup-textInput (above). */
  /* Both added up (inputGroup--postcode-textInput and inputGroup--city-textInput) + borderWidth*2  should result
   * in the width of inputGroup-textInput. */
}
.inputGroup--city-textInput {
  margin-right: 0 !important;
  width: 166px !important;
  /* IMPORTANT! Note this is dependent on the width of inputGroup-textInput (above). */
  /* Both added up (inputGroup--postcode-textInput and inputGroup--city-textInput) + borderWidth*2  should result
   * in the width of inputGroup-textInput. */
}
.inputGroup--street-textInput {
  margin-right: 0 !important;
  width: 206px !important;
  /* Patient Create View */
}
.inputGroup--housenumber-textInput {
  margin-right: 0 !important;
  width: 90px !important;
  /* Patient Create View */
}
.inputGroup--accountPostcode-textInput {
  margin-right: 0 !important;
  width: 105px !important;
  /* IMPORTANT! Note this is dependent on the width of inputGroup-textInput (above). */
  /* Both added up (inputGroup--postcode-textInput and inputGroup--city-textInput) + borderWidth*2  should result
   * in the width of inputGroup-textInput. */
}
.inputGroup--accountCity-textInput {
  margin-right: 0 !important;
  width: 141px !important;
  /* IMPORTANT! Note this is dependent on the width of inputGroup-textInput (above). */
  /* Both added up (inputGroup--postcode-textInput and inputGroup--city-textInput) + borderWidth*2  should result
   * in the width of inputGroup-textInput. */
}
/* The following inputGroup fields are used in the basket for the custom delivery address on each line */
.inputGroup-basket--firstName,
.inputGroup-basket--lastName {
  width: 188px;
}
.inputGroup-basket--postcode {
  width: 105px;
}
.inputGroup-basket--city {
  width: 271px;
}
.inputGroup-numberInput:focus,
.inputGroup-textInput:focus {
  margin-right: -2px;
  padding-left: 5px;
  padding-right: 5px;
}
.inputGroup-numberInput:focus,
.inputGroup-textInput:focus,
.inputGroup-textArea:focus {
  border-color: #547a8b;
  border-width: 2px;
  outline: 0;
}
.inputGroup-customAddressCheckbox:not(:checked) ~ .inputGroup-customAddressContainer {
  display: none !important;
}
.inputGroup-customAddressCheckbox:checked ~ .inputGroup-customAddressContainer {
  display: block;
}
.inputGroup-textArea:focus {
  padding: 2px 5px;
}
.text-right .inputGroup-numberInput:focus,
.text-right .inputGroup-textInput:focus {
  margin-left: -2px;
  margin-right: 0;
}
.inputGroup-customAddressCheckbox:not(:checked) ~ .u-inlineBlock .select2 {
  opacity: 0.5;
}
.inputGroup-customAddressCheckbox:checked ~ .u-inlineBlock .select2 {
  opacity: 1;
}
.inputGroup-textInput.is-faulty,
.inputGroup-numberInput.is-faulty {
  color: #b0053a;
  border-color: #b0053a;
}
.inputGroup-textLabel {
  color: #000000;
  display: inline-block;
  width: 200px;
  /* IMPORTANT there is a dependency to keyValue-key in the layout!!! */
  font-size: 1em;
  font-family: "Humanist Condensed Light";
  line-height: 20px;
  vertical-align: top;
  margin: 20px 0;
  box-sizing: border-box;
}
.inputGroup-hint {
  font-family: "Humanist Condensed Italic";
}
.inputGroup-input {
  margin: 20px 0;
  display: inline-block;
}
.inputGroup-checkBoxLabel {
  margin-right: 10px;
}
.inputGroup-checkBoxLabel:last-of-type {
  margin-right: 0px;
}
.inputGroup-checkBoxLabel:before {
  content: '';
  background-image: url(../images/input-sprites.png);
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-bottom: -2px;
  margin-right: 3px;
  background-repeat: no-repeat;
  cursor: pointer;
}
.inputGroup-checkBoxInput + .inputGroup-checkBoxLabel:before {
  background-position: -5px -101px;
}
.inputGroup-checkBoxInput:checked + .inputGroup-checkBoxLabel:before {
  background-position: -5px -5px;
}
.inputGroup-checkBoxInput {
  display: none;
}
.inputGroup-radioLabel {
  margin-right: 30px;
  margin-bottom: 16px;
}
.inputGroup-radioLabel div {
  font-family: "Humanist Condensed Italic";
}
.inputGroup-radioLabel:last-of-type {
  margin-right: 0;
}
.inputGroup-radioLabel:before {
  content: '';
  background-image: url(../images/input-sprites.png);
  display: inline-block;
  width: 17px;
  height: 17px;
  margin-bottom: -3px;
  margin-right: 5px;
  background-repeat: no-repeat;
}
.inputGroup-radioInput + .inputGroup-radioLabel:before {
  background-position: -4px -218px;
}
.inputGroup-radioInput:checked + .inputGroup-radioLabel:before {
  background-position: -4px -192px;
}
.inputGroup-radioInput {
  display: none;
  pointer-events: none;
}
.inputGroup-radioInput:disabled + .inputGroup-radioLabel,
.inputGroup-radioInput:not(:checked) ~ div > .subChoiceLabel {
  opacity: 0.5;
}
.inputGroup-selectInput {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border-width: 2px;
  border-color: #d5d4d3;
  background-color: transparent;
  padding: 5px 35px 5px 5px;
  height: 34px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(../images/input-sprites.png);
  background-repeat: no-repeat;
  background-position: right -5px top -151px;
  background-size: 40px;
}
.inputGroup-sizeLabel,
.inputGroup-radioLabel,
.inputGroup-variationLabel,
.inputGroup-gripTopLabel {
  cursor: pointer;
  display: inline-block;
}
.inputGroup-sizeLabel {
  display: inline-block;
  height: 35px;
  min-width: 35px;
  -webkit-border-radius: 17px;
  -moz-border-radius: 17px;
  border-radius: 17px;
  border-width: 2px;
  line-height: 31px;
  font-size: 16px;
  border-style: solid;
  text-align: center;
  margin-right: 10px;
  border-color: #8e8d8e;
  background-color: #ffffff;
  color: #8e8d8e;
  padding: 0 7px;
}
.inputGroup-radioInput:checked + .inputGroup-sizeLabel {
  background-color: #8e8d8e;
  color: #ffffff;
}
.inputGroup-errors {
  color: #b0053a;
  font-family: "Humanist Condensed Italic";
  margin-top: -15px;
  margin-left: 205px;
  padding: 0;
  list-style: none;
}
.inputGroup--noInline-errors {
  margin-left: 0;
}
.inputGroup-radioLabel img,
.inputGroup-variationLabel img,
.inputGroup-gripTopLabel img {
  pointer-events: none;
}
.inputGroup-variationLabel,
.inputGroup-gripTopLabel {
  text-align: center;
  border: 2px solid #ffffff;
  padding: 4px 10px;
  vertical-align: top;
}
.inputGroup-gripTopLabel {
  width: 120px;
  display: inline-block;
}
.inputGroup-gripTopLabel img {
  max-width: 100px;
  height: 70px;
}
.inputGroup-radioInput:checked + .inputGroup-variationLabel,
.inputGroup-radioInput:checked + .inputGroup-gripTopLabel {
  border: 2px solid #a2a19f;
}
.inputGroup-additionalLabel {
  font-family: "Humanist Condensed Italic";
  color: #a2a19f;
  display: inline-block;
  vertical-align: bottom;
  margin-bottom: 20px;
  margin-left: -40px;
}
/*
 * Sometimes label and input are not inline
 * Use inputGroup--noInline-textInput for that.
 */
.inputGroup-textInput.u-noInline {
  display: block;
  margin: 5px 0;
}
.inputGroup-textLabel.u-noInline {
  display: block;
  margin-bottom: 0;
}
.inputGroup-errors.u-noInline {
  margin-left: 0;
  margin-top: 5px;
}
/*
 * Button for input-files
 * Checkout the button in the image_field.html as an example for html + JS(!).
 */
.inputfile-button {
  width: 0.1px;
  height: 0.1px;
  visibility: hidden;
  overflow: hidden;
}
.inputfile-button + label {
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
}
.textInput--supplementProduct {
  width: 50px;
}
.inputGroup-amountInput {
  display: inline-block;
  vertical-align: top;
  margin: 0;
}
.inputGroup-helptext {
  margin-top: -17px;
}
/*
 * Some input specific utilities
 */
.inputGroup-checkBoxInput + label + .inputGroup-reason {
  display: none;
}
.inputGroup-checkBoxInput:checked + label + .inputGroup-reason {
  display: block;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
}
input[type=number] {
  -moz-appearance: textfield;
}
input[type="text"]:disabled,
input[type="number"]:disabled {
  background-color: #f2f2f2;
  cursor: not-allowed;
}
/*
 * PLACEHOLDER
 */
::-webkit-input-placeholder {
  color: #a2a19f;
  font-family: "Humanist Condensed Italic";
}
::-moz-placeholder {
  color: #a2a19f;
  font-family: "Humanist Condensed Italic";
}
:-ms-input-placeholder {
  color: #a2a19f;
  font-family: "Humanist Condensed Italic";
}
input:-moz-placeholder {
  color: #a2a19f;
  font-family: "Humanist Condensed Italic";
}
@media (max-width: 1400px) {
  .inputGroup-gripTopLabel {
    width: 110px;
  }
  .inputGroup-gripTopLabel img {
    max-width: 80px;
    height: 50px;
  }
}
@media (max-width: 1280px) {
  .input-search {
    width: 120px;
  }
  .inputGroup-textInput--newsletter {
    width: 125px;
  }
}
.productionNumberInput-input {
  font-size: 18px;
  width: 140px !important;
  height: 25px !important;
}
/*
 * keyValue is a component which displays information in form of a key, value pair.
 */
.keyValue--sub {
  margin-top: -30px;
}
.keyValue-key {
  margin: 15px 0;
  font-family: "Humanist Condensed Bold";
  color: #000000;
  width: 200px;
  /* IMPORTANT there is a dependency to formField-label in the layout!!! */
  display: inline-block;
  vertical-align: top;
}
.keyValue-value {
  margin: 15px 0;
  display: inline-block;
}
@media only screen and (max-width: 991px) {
  .keyValue-key {
    max-width: 150px;
  }
}
/*
 * Login Overlay
 */
.loginOverlay {
  position: absolute;
  right: 0;
  width: 35%;
  margin-top: 5%;
  background-color: #ffffff;
}
.inputGroup-textInput--transparent {
  background-color: rgba(255, 255, 255, 0);
}
.loginOverlay-headline {
  color: #a2a19f;
}
.retailerFinder {
  margin-top: 20px;
  margin-bottom: 20px;
}
.retailerFinder-flag {
  background-image: url("../images/login-redflag.png");
  background-size: cover;
  background-repeat: no-repeat;
  float: left;
  width: 166px;
  height: 50px;
  color: #ffffff;
  margin-left: -64px;
  line-height: 45px;
  font-size: 16px /* This font size is not defined in the styleguide */;
  padding-left: 15px;
}
.retailerFinder-flag .ofa-icon {
  font-size: 1.5em;
  line-height: 1em;
}
.loginOverlay-brand {
  /* Align the brand to the top right corner */
  margin-top: -10px;
  margin-right: -30px;
}
.loginOverlay-hint {
  color: #ffffff;
  background-color: #a2a19f;
  padding: 20px 40px;
  margin-left: -50px;
  margin-right: -30px;
  margin-bottom: -10px;
}
.retailerFinder-hint {
  float: left;
  width: 45%;
  margin-left: 20px;
  color: #b0053a;
}
/*
 * Registration Overlay
 */
.registrationOverlay {
  display: flex !important;
  position: absolute;
  right: 0;
  margin-top: 5%;
  width: 50%;
}
.registrationAdvantages {
  display: inline-block;
  vertical-align: top;
  width: 30%;
  background-color: #d5d4d3 !important;
  right: -5px;
  position: relative;
  padding: 80px 40px 40px !important;
}
.registrationOverlay-content {
  width: 70%;
  margin-left: auto;
  padding-left: 50px !important;
  /* Overwrite the shadowBox padding */
}
.registrationAdvantages-close {
  position: absolute;
  top: 20px;
  right: 20px;
}
.registrationAdvantages-list {
  margin-top: 30px;
  list-style: None;
}
/* Dash for the bullet list, since Ofa Marketing didn't like the bulletpoint */
.registrationAdvantages-list > li:before {
  content: "-";
  text-indent: -5px;
  margin-right: 10px;
}
/*The indents the second line*/
.registrationAdvantages-list > li {
  text-indent: -14px;
}
.registrationAdvantages-list li {
  margin: 15px 0;
}
/* Lower the margins between the labels and fields */
.registrationOverlay .inputGroup-textInput,
.registrationOverlay .inputGroup-textLabel {
  margin: 15px 0;
}
.registrationOverlay .inputGroup--sub {
  margin-top: -10px;
}
.registrationOverlay .inputGroup-errors {
  margin-top: -5px;
}
/*
 Tablet Styles
 */
@media only screen and (min-width: 500px) and (max-width: 991px) {
  .loginOverlay {
    width: 70%;
    background-color: rgba(255, 255, 255, 0.9) !important;
    margin: 0;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    position: absolute;
    left: 50%;
  }
  .loginOverlay-headline {
    margin-top: 50px;
  }
  .retailerFinder {
    margin-top: 80px;
  }
  .retailerFinder-hint {
    width: 55%;
  }
  .registrationOverlay {
    width: 95%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1280px) {
  .loginOverlay {
    width: 90%;
    background-color: rgba(255, 255, 255, 0.9) !important;
    margin: 0;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    position: absolute;
    left: 50%;
  }
  .loginOverlay-headline {
    margin-top: 60px;
  }
  .loginTabletLeftColumn {
    display: inline-block;
    width: 50%;
    padding: 10px 0 40px 0;
  }
  .loginTabletRightColumn {
    display: inline-block;
    width: 50%;
    float: right;
    padding: 10px 0 40px 0;
  }
  .retailerFinder {
    display: inline-block;
    margin-top: 45px;
  }
  .retailerFinder-flag {
    background-image: url("../images/login-redflag-right.png");
    display: inline-block;
    position: absolute;
    right: -11px;
  }
  .retailerFinder-hint {
    width: 58%;
    margin-left: 0;
  }
  /* Remove the hr divider since on tablet landscape the forms are in the same row */
  .loginOverlay .hr {
    visibility: hidden;
  }
  .registrationOverlay {
    width: 80%;
  }
}
@media only screen and (max-width: 991px) {
  .fullscreenBackground .shadowBox.loginOverlay {
    display: block !important;
  }
}
@media only screen and (max-width: 500px) {
  .mobileLoginWrapper {
    padding-right: 20px;
  }
  .fullscreenBackground .loginOverlay {
    width: 100vw;
    min-height: 100vh;
    margin-top: 0;
    padding-top: 80px;
  }
}
.passwordCheck .glyphicon-remove {
  color: #b0053a;
}
.passwordCheck .glyphicon-ok {
  color: #2a7216;
}
.passwordMismatch {
  -webkit-transition: opacity ease-in-out 0.2s;
  -moz-transition: opacity ease-in-out 0.2s;
  -ms-transition: opacity ease-in-out 0.2s;
  -o-transition: opacity ease-in-out 0.2s;
  transition: all ease-in-out 0.2s;
  margin-left: 200px;
  color: #b0053a;
}
.loginImprintPrivacy {
  float: right;
}
.mailingCount {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  height: 13px;
  width: 13px;
  line-height: 13px;
  background-color: #000000;
  color: #ffffff;
  font-size: 7px;
  position: absolute;
  top: 3px;
  left: 20px;
  text-align: center;
}
.mailingCount-content {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
}
.mailingCount--big {
  height: 30px;
  width: 30px;
  line-height: 30px;
  font-size: 15px;
  top: -5px;
  left: 25px;
}
.measurementColumn-header {
  min-height: 65px;
}
.measurementSvgContainer {
  text-align: center;
}
.measurementSvgContainer svg {
  max-width: 100%;
  height: 400px;
}
.measurementResult--description {
  width: 250px;
  text-align: center;
  margin: 0 auto;
}
.measurementResult-backButton {
  position: absolute;
  left: 0;
}
/* This is for the patient detail measurement view */
.measurement-headline {
  border-bottom: 1px solid #000000;
  font-family: "Humanist Condensed Bold";
  font-size: 1.27em;
  padding-bottom: 5px;
  margin-bottom: 3px;
}
.measurement-subHeadline {
  margin-top: 3px;
  border-bottom: 1px solid #000000;
  font-family: "Humanist Condensed Bold";
  font-size: 1em;
  padding-bottom: 3px;
  margin-bottom: 3px;
}
.measurement-letter {
  font-family: "AGaramondPro-Italic";
  margin-right: 0.1em;
}
.measurementImage svg {
  max-height: 400px;
  max-width: 100%;
}
.u-measurementGrow {
  margin-top: 310px;
}
.u-measurement-borderRight {
  border-right: 1px solid #000000;
}
@media (max-width: 1040px) {
  .u-measurementGrow {
    margin-top: 0;
  }
  .u-measurement-borderRight {
    border: 0;
  }
}
.toleranceRange {
  color: #8e8d8e;
}
.preventClickOverlayColored {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: white;
  opacity: 0.7;
}
@media (max-width: 1040px) {
  .measurementSvgContainer svg {
    max-width: 100%;
    height: 350px;
  }
}
.mobile-measurements {
  display: none;
}
.desktop-patient-measurement {
  display: block;
}
.mobile-patient-measurement {
  display: none;
}
@media only screen and (max-width: 991px) {
  .desktop-patient-measurement {
    display: none;
  }
  .mobile-patient-measurement {
    display: block;
  }
  .dropdown-arrow path {
    display: block !important;
  }
  .panel-heading a[aria-expanded="true"] .dropdown-arrow {
    transform: rotate(90deg);
  }
  .js-measurementFormModalBody {
    display: none;
  }
  .mobile-measurements.js-measurementFormModalBody {
    display: block;
  }
  .mobile-measurements input {
    scroll-margin: 100px;
  }
  .row.bottom-grid::before {
    content: "";
    display: none;
  }
  .mobile-measurements .bottom-grid,
  .mobile-patient-measurement .bottom-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 0.5em;
  }
  .mobile-measurements .measurementInput-input,
  .mobile-patient-measurement .measurementInput-input {
    width: 50%;
  }
  .mobile-measurements .panel-heading a,
  .mobile-patient-measurement .panel-heading a {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }
  .desktop-tolerances .measurementSvgContainer {
    display: none;
  }
  .desktop-tolerances .row-eq-height {
    justify-content: space-between;
  }
  .desktop-tolerances .row-eq-height .col-md-4 {
    width: 50%;
  }
  .desktop-tolerances .left-leg-row {
    display: flex;
    flex-direction: column-reverse;
  }
  .desktop-tolerances .u-visibilityHidden {
    display: none;
  }
  .tolerances-submit .pull-right {
    float: none !important;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    row-gap: 1em;
    text-align: center;
    max-width: 100%;
  }
  .tolerances-submit .pull-right form {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row: 1;
  }
  .tolerances-submit .pull-right form label {
    width: 95.5%;
    margin: 0 10px;
  }
  .tolerances-submit .pull-right .btn--primary {
    grid-row: 2;
    grid-column: 1;
  }
  .tolerances-submit .pull-right .btn--primaryBlue {
    grid-row: 2;
    grid-column: 2;
  }
  .panel-collapse .btn--link {
    width: 100%;
    text-align: center;
  }
  .length-tolerance {
    margin-top: 2em;
  }
  #left_foot .measurementSvgContainer svg,
  #right_foot .measurementSvgContainer svg {
    clip-path: polygon(0 30%, 100% 30%, 100% 70%, 0% 70%);
    margin-top: -6em;
    margin-bottom: -9em;
  }
}
@media only screen and (max-width: 500px) {
  .js-modal-measurement .modal-lg {
    width: 100vw;
    max-width: 100vw;
    height: auto;
  }
  .js-modal-measurement .modal-lg .modal-content {
    height: 100%;
  }
  .desktop-tolerances .table-data--slim {
    padding: 0;
  }
  .js-measurementModalContent {
    padding-left: 1em;
    padding-right: 1em;
  }
  .bottom-grid {
    grid-template-rows: 1fr;
  }
  .tolerances-submit .pull-right {
    grid-template-rows: 3fr;
    grid-template-columns: 1fr;
  }
  .tolerances-submit .pull-right form {
    grid-column-start: 1;
    grid-column-end: 1;
  }
  .tolerances-submit .pull-right .btn--primary {
    grid-row: 3;
    grid-column: 1;
  }
  .tolerances-submit .pull-right .btn--primaryBlue {
    grid-row: 2;
    grid-column: 1;
  }
  .measurementResult-backButton {
    bottom: 9.5em;
  }
  .measurement-results-wrapper .measurementResult-backButton {
    bottom: 0;
  }
  .measurementSvgContainer svg {
    height: 280px;
  }
}
.measurementKeyValue:nth-child(odd) {
  background-color: #d5d4d3;
}
.measurementKeyValue:nth-child(even) {
  background-color: #ffffff;
}
.measurementKeyValue {
  padding: 5px 10px;
  border-bottom: 1px solid #a2a19f;
}
.measurementKeyValue-helper {
  margin-top: 69px;
  border-bottom: 1px solid #a2a19f;
}
.measurementKeyValue-error {
  color: #9d261d;
}
.measurementKeyValue-value input {
  max-width: 70%;
}
.measurementKeyValue-value i {
  float: right;
}
.measurementInput-label {
  display: inline-block;
  width: 20px;
}
input + .measurementInput-labelRadio + .measurementInput-label ~ input,
input + .measurementInput-labelRadio + .measurementInput-label ~ i {
  display: none;
}
input:checked + .measurementInput-labelRadio + .measurementInput-label ~ input,
input:checked + .measurementInput-labelRadio + .measurementInput-label ~ i {
  display: inline-block;
}
.measurementInput-labelRadio:before {
  content: '';
  background-image: url(../images/input-sprites.png);
  display: inline-block;
  width: 17px;
  height: 17px;
  margin-bottom: -3px;
  margin-right: 5px;
  background-repeat: no-repeat;
  background-position: -4px -218px;
}
input:checked + .measurementInput-labelRadio:before {
  background-position: -4px -192px;
}
.measurementInput-label span {
  font-family: "AGaramondPro-Italic";
  margin-right: 0.1em;
}
.w100 .measurementInput-label {
  width: 100px;
}
.measurementInput-label {
  margin-bottom: 0;
}
.measurementInput-input {
  width: 80px;
  height: 20px;
  border: 2px solid #a2a19f;
}
.measurementInput-input:focus {
  border-color: #547a8b;
  outline: none;
}
@media (max-width: 1040px) {
  .measurementInput-input {
    width: 65px;
  }
  .measurementForm h4 {
    margin-bottom: 0;
    margin-top: 5px;
  }
}
/*
 * Messages component. Leveraging Django's messaging framework, messages are styled here.
 */
.messages {
  list-style: none;
  padding: 0;
}
.message {
  border-style: solid;
  border-width: 2px;
  padding: 10px;
}
.message b {
  font-family: "Humanist Condensed Bold";
  text-transform: uppercase;
  margin-right: 10px;
  margin-left: 5px;
}
.message--warning {
  border-color: #b0053a;
  color: #b0053a;
}
.message--info,
.message--success {
  border-color: #547a8b;
  color: #547a8b;
}
.message-closeButton {
  float: right;
  font-size: 1.27em;
  margin-top: -5px;
  border: 0;
  background: transparent;
}
.message--warning .message-closeButton {
  color: #b0053a;
}
.message--info .message-closeButton,
.message--success .message-closeButton {
  color: #547a8b;
}
.modal {
  text-align: center;
  z-index: 10100;
}
.modal-backdrop {
  z-index: 10000;
}
.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}
.modal-content {
  -webkit-box-shadow: 0 0 0 0 transparent;
  -moz-box-shadow: 0 0 0 0 transparent;
  box-shadow: 0 0 0 0 transparent;
  border: 0;
  padding: 0 10px;
}
.modal-footer {
  border-top: 0;
  padding: 0;
}
.modal-header {
  border-bottom-color: #a2a19f;
  border-bottom-width: 2px;
  margin-bottom: 15px;
}
.modal-header .close {
  margin-top: 14px;
}
.modal-header h3 {
  color: #a2a19f;
  margin-top: 10px;
}
.modal-lg {
  width: 1200px;
  /* Bootstraps default is 900px */
  max-width: 95%;
}
.modal-lg .modal-content {
  padding: 10px 15px;
  width: auto;
  height: auto;
}
.modal-lg .modal-header {
  padding: 0;
}
.modal-noBorder .modal-header {
  border: 0;
}
.modal-lg .modal-body {
  padding: 0;
}
.modal-lg .close {
  font-size: 2.4em;
  margin-top: 5px;
}
.modal-brand {
  margin: 20px 0 10px 0;
}
.modal-subheadline {
  font-size: 1.45em;
}
.modal-brandText {
  margin-top: 30px;
  font-size: 1.27em;
  margin-bottom: 20px;
}
.modal-modalImage {
  float: right;
  margin-top: -130px;
  padding-left: 30px;
}
.modal-errors {
  display: none;
  font-family: "Humanist Condensed Italic";
  color: #b0053a;
  margin-top: 15px;
}
.variant {
  max-width: 200px;
  display: inline-block;
  margin-right: 50px;
}
.variantTitle {
  font-size: 1.27em;
  width: 100%;
  height: 2.7em;
  border-bottom: 1px solid #000000;
  vertical-align: bottom;
  position: relative;
}
.variantTitle-content {
  position: absolute;
  padding-bottom: 5px;
  left: 0;
  bottom: 0;
}
.variant-image {
  max-height: 150px;
  max-width: 190px;
  margin-bottom: 5px;
}
.variant-additionalInformation {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  line-height: 1em;
  height: 2em;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid #000000;
}
.variant-inputGroup {
  margin-top: 5px;
}
button.close,
button.close:hover,
button.close:focus {
  outline: 0;
  color: #a2a19f;
}
button.close {
  font-size: 2em;
  opacity: 1;
}
@media screen and (min-width: 768px) {
  .modal:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%;
  }
  .modal-header h3 {
    margin-top: 20px;
  }
  .modal-lg .close {
    font-size: 2.4em;
    margin-top: 12px;
  }
  .modal-lg {
    max-width: 85%;
  }
}
@media only screen and (min-width: 500px) and (max-width: 991px) {
  .modal-lg {
    max-width: none;
    width: 90%;
  }
  .modal-lg .modal-content {
    padding: 10px 40px 30px 40px;
  }
}
@media (max-height: 720px) {
  .modal-dialog {
    margin: 10px auto;
  }
}
/*
 * NAVIGATION STYLES
 *
 * This file contains all styles for the navigation.
 *
 */
/*
 * Body CSS for fixed navigation bar. Note this is dependent on the complete height of the navigation bar.
 */
body {
  padding-top: 89px;
}
.navbarOfa {
  background-color: #ffffff;
  border-color: #a2a19f;
}
.navbarOfa-navbarItem {
  color: #000000;
  font-size: 1em;
  text-decoration: none;
}
.navbarOfa-navbarItem .ofa-icon {
  font-size: 24px;
}
.nav > li > a.navbarOfa-navbarItem {
  padding-left: 14px;
  padding-right: 14px;
  padding-bottom: 18px;
  padding-top: 18px;
}
.dropdown-menu {
  width: 940px;
  border: 0;
  border-top: 1px solid #a2a19f;
  top: 60px;
  padding: 30px 5px 30px 40px;
  -webkit-transform: translateX(50%);
  -moz-transform: translateX(50%);
  -ms-transform: translateX(50%);
  -o-transform: translateX(50%);
  transform: translateX(50%);
}
.exportUser .dropdown-menu {
  width: 1080px;
}
.dropdown-menu > li > a {
  color: #a2a19f;
  font-size: 1.27em;
  font-family: "Humanist Condensed Bold";
}
.dropdown-menu > li > a:hover {
  background-color: inherit;
  color: #a2a19f;
}
.menu-category + ul > li {
  padding: 5px 0;
}
.navbar-left {
  padding-top: 7px;
  padding-bottom: 7px;
  color: #000000;
}
.nav > li > a.navbar--neutral-navbarItem {
  padding-top: 7px;
  padding-bottom: 7px;
  position: relative;
}
.nav > li.active > a.navbarOfa-navbarItem {
  border-bottom: 3px solid #8e8d8e;
  padding-bottom: 16px;
}
.nav > li > a:hover.navbarOfa-navbarItem:hover,
.nav > li > a.navbarOfa-navbarItem:focus {
  color: #000000;
  background-color: #d5d4d3;
}
.navbar--neutral {
  background-color: #d5d4d3;
  border-bottom: 1px solid #a2a19f;
}
.navbar--neutral-navbarItem {
  color: #000000;
  font-size: 0.9em;
  text-decoration: none;
}
.nav > li > a:hover.navbar--neutral-navbarItem:hover,
.nav > li > a.navbar--neutral-navbarItem:focus {
  background-color: #a2a19f;
  color: #ffffff;
}
.navbarOfa-brand > img {
  height: 53px;
}
.navbar--bottom .navbar-nav {
  height: 53px;
}
.navbar-shop--upper li:not(:first-child):before {
  content: "|";
  position: absolute;
  margin-top: 5px;
  margin-left: -3px;
}
.link-banner {
  width: 120px;
  position: relative;
  top: -1px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  text-align: center;
  margin-left: 10px;
}
.link-banner > a {
  background-color: #1c6a85;
  padding: 15px 0 16px 0 !important;
  overflow: hidden;
  line-height: 20px;
  color: #ffffff;
}
.link-banner > a:hover {
  background-color: #346274 !important;
  color: #ffffff !important;
}
.link-banner::before {
  content: " ";
  position: absolute;
  left: -10px;
  bottom: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 0;
  border-color: transparent #033343 transparent transparent;
}
.link-banner a > div:first-of-type {
  width: 88%;
  float: left;
}
.link-banner a > div:last-of-type span {
  position: relative;
  top: 10px;
  right: 14px;
}
@media (max-width: 1024px) {
  .navbar-left {
    display: none;
  }
}
@media (min-width: 1300px) {
  .navbarOfa-navbarItem {
    font-size: 1.2em;
  }
  .navbar--neutral-navbarItem {
    font-size: 1em;
  }
  .link-banner {
    width: 145px;
    margin-left: 10px;
  }
}
@media (min-width: 768px) {
  .navbar-nav > li > a {
    padding-bottom: 12px;
  }
}
@media (max-width: 1180px) {
  .nav > li > a.navbar--neutral-navbarItem {
    padding-left: 13px;
    padding-right: 13px;
  }
  .navbar-nav > li > a {
    padding-bottom: 7px;
  }
  .navbarOfa-navbarItem > span {
    display: block;
    text-align: center;
  }
  .navbarOfa-navbarItem > .navbarOfa-cartQuantity {
    display: inline;
  }
  .navbarOfa-brand > img {
    margin-top: 7px;
  }
  .dropdown-menu {
    top: 67px;
  }
  .navbar--bottom .navbar-nav {
    height: 67px;
  }
  .link-banner {
    width: 125px;
    margin-left: 15px;
  }
  .link-banner > a {
    line-height: 24px !important;
  }
  .nav > li > a.navbarOfa-navbarItem {
    padding-bottom: 11px;
    padding-top: 12px;
  }
  .nav > li.active > a.navbarOfa-navbarItem {
    padding-bottom: 8px;
  }
}
@media (min-width: 1181px) {
  .navbarOfa-brand > img {
    height: 60px;
  }
}
.mobile-navbar {
  display: none;
}
.mobile-navbar-collapse {
  display: none;
}
@media only screen and (max-width: 991px) {
  body {
    padding-top: 0 !important;
  }
  .hoverSearch {
    display: block;
    top: 4.3em;
    z-index: 15;
    width: 100vw;
    right: 0;
  }
  .navbar:not(.mobile-navbar) {
    display: none;
  }
  .mobile-navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 1em;
  }
  .mobile-navbar .hamburger {
    background: none;
    border: none;
  }
  .mobile-navbar-collapse .nav-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    list-style-type: none;
    padding-left: 0;
    font-size: 16px;
  }
  .mobile-navbar-collapse .nav-list li:last-child {
    border-bottom: 1px solid #a2a19f;
  }
  .mobile-navbar-collapse .nav-list li {
    border-top: 1px solid #a2a19f;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 1.5em;
  }
  .mobile-navbar-collapse .nav-list li a {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    padding-left: 1em;
    padding-right: 1em;
    display: inline-block;
  }
  .mobile-navbar-collapse .nav-list li ul {
    padding-left: 0;
    list-style-type: none;
  }
  .mobile-navbar-collapse .nav-list li[aria-expanded="true"] svg {
    transform: rotate(0deg);
    transition: 0.3s ease-in-out;
  }
  .mobile-navbar-collapse .nav-list li.js-navigationShop {
    background-color: #1c6a85;
  }
  .mobile-navbar-collapse .nav-list li.js-navigationShop a {
    color: #ffffff;
  }
  .mobile-navbar-collapse .nav-list .js-cart.cart {
    display: flex !important;
  }
  .mobile-navbar-collapse .nav-list li svg {
    transition: 0.3s ease-in-out;
    transform: rotate(-90deg);
  }
  .mobile-nav-children li {
    display: block !important;
    padding-right: 0 !important;
  }
  .mobile-nav-children li a {
    padding-left: 2em !important;
  }
  .mobile-navbar-collapse.active {
    display: block;
  }
  .mobile-nav-children {
    padding: 0em;
  }
}
.hint--superuser {
  position: fixed;
  top: 120px;
  right: 20px;
  background: #ed143d;
  z-index: 10000;
  padding: 10px;
  color: white;
}
.onboardingImage {
  display: inline-block !important;
  max-height: 100%;
}
.onboardingSlide .modal-body {
  line-height: 560px;
  height: 560px;
  text-align: center;
}
.onboardingModal .slick-dots {
  margin-left: -40px;
  bottom: 35px;
}
.onboardingModal .slick-dots li button:before {
  content: '';
}
.onboardingModal .slick-dots li button {
  height: 5px;
  width: 5px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: #d5d4d3;
}
.onboardingModal .slick-dots li.slick-active button {
  background-color: #a2a19f;
}
.onboardingModal .slick-list {
  z-index: 10000;
}
.onboardingSlide p,
.onboardingSlide li {
  line-height: 1.4em;
  text-align: left;
  font-size: 1.3em;
}
@media screen and (max-height: 768px) {
  .onboardingImage {
    width: auto;
    max-height: 100%;
    display: inline-block !important;
    max-width: 100%;
  }
  .onboardingSlide .modal-body {
    line-height: 440px;
    height: 440px;
    text-align: center;
  }
}
@media screen and (max-width: 1200px) {
  .onboardingSlide .modal-body {
    height: 420px;
    line-height: 420px;
  }
  .onboardingSlide p,
  .onboardingSlide li {
    font-size: 1.1em;
    line-height: 1.3em;
  }
}
.panel {
  border: 0;
  -webkit-box-shadow: 0 0 0 0 #000;
  -moz-box-shadow: 0 0 0 0 #000;
  box-shadow: 0 0 0 0 #000;
}
.panel-heading {
  background-color: #d5d4d3;
}
.panel-group {
  margin-bottom: 40px;
}
.panel-title {
  font-size: 18px;
}
.collapsed .panelIndicator {
  transform: rotate(90deg);
  margin-top: 3px;
}
.pagination-item {
  display: inline-block;
  text-align: center;
  line-height: 26px;
  border: 2px solid #8e8d8e;
  height: 30px;
  width: 30px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.pagination-item.is-active {
  border-color: #a2a19f;
  background-color: #d5d4d3;
}
.pagination-item:hover {
  color: #ffffff;
  background-color: #a2a19f;
  border-color: #8e8d8e;
}
.pagination-item-circle {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background: transparent;
  border: 1px solid;
  border-color: #a2a19f;
}
.pagination-item-circle:before {
  content: '';
}
.pagination-item-circle.is-active {
  background-color: #8e8d8e;
}
.u-price-font,
.u-price-fontSupplement,
.u-price-font-crossSelling {
  color: #8c8c8c;
  font-size: 1.1em;
  font-weight: bold;
  margin-top: 10px;
}
.u-price-fontAdMed {
  color: #000000;
  font-size: 0.9em;
  margin-top: 10px;
}
.supplementTile-amount {
  text-align: right;
}
.inputGroup-price {
  float: left;
  margin-top: 20px;
}
@media (max-width: 1260px) {
  .inputGroup-price {
    float: none;
    margin-top: 28px;
    margin-bottom: -10px;
  }
}
.specificationPrice {
  font-size: 18px;
  font-weight: bold;
  display: block;
}
.specificationPrice .js-listPrice {
  font-size: 16px;
  margin-right: -23px;
}
.specificationPrice .js-price,
.specificationPrice .js-yourPrice {
  color: #1c6a85;
}
.specificationPrice .js-priceFrom {
  margin-right: -20px;
}
.js-basketGroupTable .js-singlePrice,
.js-basketGroupTable .js-price {
  font-size: 18px;
}
.js-basketGroupTable .js-singlePrice:after,
.js-basketGroupTable .js-price:after,
.buttons-price .js-priceFrom:after,
.priceCurrency:after,
.js-basketNetPrice:after,
.js-price .js-priceField:after,
.js-yourPrice .js-priceField:after,
.js-priceFrom .js-priceFromField:after,
.js-listPrice .js-listPriceField:after {
  content: ' €';
  font-family: Arial, sans-serif;
}
.crossSellingCurrency,
.supplementCurrency,
.orderHistoryCurrency {
  font-family: Arial, sans-serif;
}
.basketNetPrice,
.basketNetPriceDescription {
  font-size: 18px;
  font-weight: bold;
  visibility: hidden;
}
.basketNetPriceDescription {
  text-align: right;
}
.panel-group:last-of-type .basketNetPrice,
.panel-group:last-of-type .basketNetPriceDescription {
  visibility: visible;
}
.productSpec-inputGroup {
  font-size: 14px;
}
.productSpec-inputGroup-input {
  display: inline-block;
  margin-bottom: 10px;
}
.productSpec-inputGroup .textLabel,
.productSpec-labelValue .textLabel {
  display: inline-block;
  font-size: 1.27em;
}
.productSpec-labelValue .value,
.productSpec-inputGroup .btn {
  display: inline-block;
}
.productSpec-information {
  text-transform: uppercase;
  cursor: pointer;
  margin: 20px 0;
}
.productSpec-information i {
  margin-right: 5px;
}
.productSpec-claim {
  font-size: 1.27em;
  width: 100%;
  margin: 5px 0;
}
.productSpec-textArea {
  width: 100%;
  height: 100px;
  border: 1px solid #a2a19f;
  resize: none;
  padding: 5px 10px;
  margin-top: 5px;
}
.productSpec-textArea:focus {
  padding: 4px 9px;
}
.configurationLower {
  position: relative;
  opacity: 0.3;
}
.preventClickOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
}
.productSpec-color--preview {
  width: 40px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  position: relative;
  margin-bottom: 0;
  pointer-events: none;
  display: block;
}
input:checked + .productSpec-color {
  border: 2px solid #a2a19f;
}
.productSpec-color {
  vertical-align: top;
  padding: 3px;
  cursor: pointer;
  display: inline-block;
}
.productSpec-color--item {
  text-align: center;
  display: inline-block;
  width: 85px;
  margin-bottom: 10px;
  vertical-align: top;
}
.productSpec-sizeTableButton {
  color: #a2a19f;
  border: 0;
  background-color: transparent;
}
.productSpec-sizeTableButtonContainer {
  margin-top: -15px;
  margin-left: 200px;
  margin-bottom: 10px;
}
.productSpec-sizeTableButton:focus {
  outline: 0;
}
.productSpec-alternateTile {
  box-sizing: border-box;
  display: inline-block;
  padding: 10px 30px;
  -webkit-box-shadow: 0 0 20px 2px #bbb;
  -moz-box-shadow: 0 0 20px 2px #bbb;
  box-shadow: 0 0 20px 2px #bbb;
  background-color: white;
  width: 100%;
  height: 200px;
  padding: 10px 15px;
}
.productSpec-alternateLabel {
  font-family: "Humanist Condensed Bold";
  display: block;
  font-size: 16px;
  color: #a2a19f;
  margin-bottom: 10px;
}
.productSpec-header-actions {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.productSpec-header-actions .pull-right {
  float: none !important;
}
.productSpec-inputGroup.additionalInformation-mobile {
  display: none;
}
.product-amount {
  display: inline-block;
}
.product-amount span {
  display: block;
}
.product-amount .product-amount-input-wrapper {
  display: flex;
  flex-direction: row;
}
.product-amount .product-amount-input-wrapper .inputGroup-textInput {
  margin: 0 0.2em;
}
.product-amount .product-amount-input-wrapper .product-amount-btn {
  background: none;
  border: none;
  padding: 0;
}
@media only screen and (max-width: 991px) {
  .productSpec-inputGroup.additionalInformation-desktop {
    display: none;
  }
  .productSpec-inputGroup.additionalInformation-mobile {
    display: block;
  }
  .productSpec-header-actions {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    justify-content: space-evenly;
  }
  .productSpec-header-actions * {
    float: none;
  }
  .productSpec-header-actions .btn--primary {
    line-height: 2em;
  }
}
@media only screen and (max-width: 500px) {
  .productSpec-header-actions .btn--primary {
    width: 100%;
    margin-right: 0;
    text-align: center;
  }
}
.select2-search {
  display: none;
}
.selection:focus,
.select2-container:focus,
.select2-selection:focus {
  outline: 0;
}
.select2-dropdown,
.select2-container--default .select2-selection--single {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border-width: 1px;
  border-style: solid;
}
.select2-container .select2-selection--single {
  height: 26px;
  margin-top: -2px;
}
.select2-container--default .select2-selection--single {
  border-color: #a2a19f;
}
.select2-container--focus .select2-selection--single {
  border-color: #547a8b;
  border-width: 2px;
}
.select2-dropdown,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--single {
  border-color: #547a8b;
  border-width: 2px;
  z-index: 20000;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border: 0;
  height: 26px;
  width: 28px;
  background-image: url(../images/input-sprites.png);
  background-position: -5px -156px;
  position: relative;
  display: block;
  top: 0;
  right: 0;
  left: auto;
  margin: 0;
}
.select2-container--focus .select2-selection--single .select2-selection__arrow b {
  background-position: -5px -126px;
}
.select2-container--default.select2-container--open.select2-container--below .select2-selection--single b {
  background-position: -5px -126px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  width: 28px;
  top: -2px;
  right: 0;
}
.select2-results {
  -webkit-box-shadow: 0 5px 20px 2px #bbb;
  -moz-box-shadow: 0 5px 20px 2px #bbb;
  box-shadow: 0 5px 20px 2px #bbb;
}
.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #eaeae9;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #d5d4d3;
  color: #000000;
}
.select2-results__option[aria-selected]:hover {
  background-color: #d5d4d3;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #000000;
  line-height: 25px;
  padding-right: 25px;
  padding-left: 8px;
}
.select2-container--focus .select2-selection--single .select2-selection__rendered {
  line-height: 23px;
  padding-left: 7px;
}
.select2-container.is-faulty {
  border: 1px solid #b0053a;
}
/* Different widths for dropdowns */
.u-w80 > .select2-container {
  width: 80px !important;
}
.u-w90 > .select2-container {
  width: 90px !important;
}
.u-w100 > .select2-container {
  width: 100px !important;
}
.u-w130 > .select2-container {
  width: 130px !important;
}
.u-w150 > .select2-container {
  width: 150px !important;
}
.u-w200 > .select2-container {
  width: 200px !important;
}
.u-w250 > .select2-container {
  width: 250px !important;
}
.u-w300 > .select2-container {
  width: 300px !important;
}
.u-w380 > .select2-container {
  width: 380px !important;
}
.u-w400 > .select2-container {
  width: 400px !important;
}
/* Widths that adapt according to screen size */
.u-w350A > .select2-container {
  width: 200px !important;
}
@media (min-width: 768px) {
  .u-w350A > .select2-container {
    width: 300px !important;
  }
}
@media (min-width: 992px) {
  .u-w350A > .select2-container {
    width: 350px !important;
  }
}
.u-w100P > .select2-container {
  max-width: 100% !important;
  width: 100% !important;
}
.select2-container--default .select2-selection--single .select2-selection__arrow,
.select2-container--focus .select2-selection--single .select2-selection__rendered,
.select2-container--default .select2-selection--single .select2-selection__rendered {
  margin: 0;
}
/*
 * The shadowBox is an important part of Ofa's branding.
 */
.shadowBox {
  box-sizing: border-box;
  display: inline-block;
  padding: 10px 30px;
  -webkit-box-shadow: 0 0 20px 2px #bbb;
  -moz-box-shadow: 0 0 20px 2px #bbb;
  box-shadow: 0 0 20px 2px #bbb;
  background-color: white;
}
.shadowBox--noTopShadow {
  -webkit-box-shadow: 0 5px 20px -1px #bbb;
  -moz-box-shadow: 0 5px 20px -1px #bbb;
  box-shadow: 0 5px 20px -1px #bbb;
}
.shadowBox-headline {
  margin: 20px 0;
  color: #a2a19f;
  font-size: 1.27em;
  font-family: "Humanist Condensed Bold";
}
.shadowBox-headline.shadowBox-headline-toplevel {
  color: #000;
}
.sizeTable {
  width: 100%;
  margin: 0;
  padding: 0;
}
.sizeTable-tableHeadline {
  font-family: "Humanist Condensed Bold";
  padding: 5px 10px;
}
.sizeTable-tableData {
  padding: 5px 10px;
  color: #000000;
  vertical-align: top;
}
.sizeTable-tableRow {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.sizeTable--center .sizeTable-tableHeadline,
.sizeTable--center .sizeTable-tableData {
  text-align: center;
}
.sizeTable-tableData--separate {
  border-collapse: collapse;
  border-right: 15px solid white;
}
.sizeTable .sizeTable-tableData--textLeft {
  text-align: left;
}
.sizeTable-wide-highlight {
  background-color: #e4e0dc;
}
/*
 * Sidetabs are used for sub-navigations e.g. account settings
 */
.sideTabs {
  list-style-type: none;
  margin: 20px 0;
  padding: 0;
  width: 170px;
}
.sideTabs-tab {
  padding-top: 8px;
  padding-right: 50px;
  position: relative;
}
.sideTabs-tab a,
.sideTabs-tab a:visited .sideTabs-tab a:link,
.sideTabs-tab a:active {
  color: #000000;
  text-decoration: none;
}
.sideTabs-tab.is-active:after {
  content: '\e258';
  position: absolute;
  right: 0;
  top: 11px;
  color: #b0053a;
  font-family: 'Glyphicons Halflings';
  font-size: 0.8em;
}
.sideTabs-tab.is-active a,
.sideTabs-tab.is-active a:active,
.sideTabs-tab.is-active a:visited,
.sideTabs-tab.is-active a:link,
.sideTabs-tab.is-active a:hover {
  color: #b0053a;
  font-family: "Humanist Condensed Bold";
}
.spinner {
  position: absolute;
  animation: spin 1s infinite linear;
  -webkit-animation: spin2 1s infinite linear;
}
.spinner:before {
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  content: "\e031";
  font-size: 30px;
}
.spinner--inline {
  left: 0;
}
.spinner--small {
  line-height: 15px;
}
.spinner--small:before {
  font-size: 10px;
}
.spinner--small-container {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
}
@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}
@-webkit-keyframes spin2 {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
.supplementTile {
  float: left;
  width: 20%;
  padding: 0 15px;
}
.supplementTile-headline {
  font-size: 1.27em;
  height: 50px;
  vertical-align: bottom;
  display: table-cell;
}
.supplementTile-image {
  max-width: 100%;
  line-height: 200px;
  max-height: 200px;
}
.supplementTile-imageContainer {
  text-align: center;
  width: 100%;
  height: 200px;
}
.supplementTile-description {
  height: 4em;
  overflow: hidden;
}
.supplementTile-amount {
  margin-top: -18px;
}
.supplementTile-claim {
  line-height: 1em;
}
@media (max-width: 1200px) {
  .supplementTile-claim {
    line-height: 1em;
    height: 55px;
  }
}
@media (max-width: 1400px) {
  .supplementTile-claim {
    height: 40px;
  }
}
@media only screen and (max-width: 991px) {
  .productSpec,
  .js-specificationButtons {
    max-width: 90%;
    margin: auto;
  }
  .js-specificationButtons {
    float: none !important;
  }
  .productSpec .brandFilter {
    display: flex;
  }
  .crossselling-wrapper {
    max-width: 90%;
    margin: auto;
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .crossselling-wrapper .supplementTile {
    width: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .crossselling-wrapper {
    grid-template-columns: 1fr;
  }
}
/*
 * Table styles
 */
@media (max-width: 767px) {
  .table-action--mobile {
    display: block;
    width: 100%;
    margin-bottom: 20px;
  }
}
.table {
  border-collapse: separate;
  white-space: nowrap;
}
.table--clean {
  min-width: 100%;
  border-collapse: separate;
  white-space: nowrap;
}
.table-headline {
  font-family: "Humanist Condensed Bold";
  color: #000000;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #000000;
  border-top: 0 !important;
  padding: 10px 0 10px 20px;
}
.table-headline:first-child,
.table-headline:last-child {
  padding-right: 10px;
}
.table-data {
  border-bottom-color: #000000;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  padding: 20px 0 20px 20px;
  position: relative;
}
.table-data:first-child,
.table-data:last-child {
  padding-right: 10px;
}
.parent .table-data {
  border-bottom: 0;
}
.table-data--slim {
  padding: 8px;
}
.table-row {
  border-bottom: 1px solid #000000;
}
.table-row.even .table-data {
  background-color: #ffffff;
}
.table-row.odd .table-data {
  background-color: #eaeae9;
}
.table-row:hover .table-data {
  background-color: #d5d4d3;
}
.table-row:focus .table-data {
  background-color: inherit;
}
.table-data--action {
  text-align: right;
}
.table-row:hover .table-action {
  color: #547a8b;
}
.table-action,
.table-action:focus,
.table-action:link,
.table-action:active,
.table-action:visited {
  color: #547a8b;
  margin-left: 10px;
  white-space: nowrap;
  cursor: pointer;
}
.table-row--basket {
  height: 170px;
}
.table-row--basket .table-data:first-child {
  padding-left: 20px;
}
.table-head--basket .table-headline {
  border-bottom: 1px solid #000000;
}
.table-row--basket .table-data {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #000000;
}
/*
 * Table Utilities
 */
.u-tableShrinkable {
  table-layout: fixed;
}
/* DataTables CSS */
table.dataTable.dtr-column > tbody > tr > td.control:before,
table.dataTable.dtr-column > tbody > tr > th.control:before {
  font-family: 'ofa-icons';
  content: "\51";
  font-size: 20px;
  line-height: 20px;
  border: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  color: #8e8d8e;
}
table.dataTable.dtr-column > tbody > tr.parent td.control:before,
table.dataTable.dtr-column > tbody > tr.parent th.control:before {
  margin-left: -6px;
  background-color: transparent;
  content: "\51";
  transform: rotate(90deg);
  color: #547a8b;
}
table.dataTable > tbody > tr.child ul {
  margin-left: 65px;
  margin-bottom: 10px;
}
table.dataTable > tbody > tr.child span.dtr-title {
  font-family: "Humanist Condensed Bold";
  width: 165px;
}
table.dataTable > tbody > tr.child ul li {
  border-bottom: 0;
}
table.dataTable > tbody > tr.child td {
  border-bottom: 1px solid #000000;
}
.table-row.even + .child > td {
  background-color: #ffffff;
}
.table-row.odd + .child > td {
  background-color: #eaeae9;
}
.table-row.even:hover + .child > td,
.table-row.odd:hover + .child > td {
  background-color: #d5d4d3;
}
@media only screen and (max-width: 991px) {
  .patient-list-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    justify-items: center;
  }
  .patient-list-wrapper > * {
    width: 90%;
  }
  .patient-list-wrapper .wrapper-grid-1 {
    grid-row: 1;
  }
  .patient-list-wrapper .wrapper-grid-2 {
    grid-row: 1;
    grid-column: 2 / span 3;
  }
  .patient-list-wrapper .grid-row-1 {
    grid-row: 1;
    grid-column: 1;
  }
  .patient-list-wrapper .grid-row-2 {
    grid-row: 2;
    grid-column-start: 1;
    grid-column-end: 4;
  }
  .patient-list-wrapper .grid-row-3 {
    grid-row: 2;
    grid-column: 3;
    justify-self: end;
  }
  .patient-list-wrapper .grid-row-4 {
    grid-row: 2;
    grid-column: 1;
  }
  .patient-list-wrapper .grid-row-5 {
    grid-row: 3;
    grid-column-start: 1;
    grid-column-end: 4;
  }
  .patient-list-wrapper .u-marginTop35--negative {
    margin-top: 0;
  }
  .patient-list-wrapper .u-marginTop30.text-right {
    text-align: left;
    margin-top: 0;
  }
  .patient-list-wrapper .u-marginTop30 {
    margin-top: 0;
  }
}
@media only screen and (max-width: 769px) {
  .patient-list-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
  .patient-list-wrapper .grid-row-1 {
    grid-row: 1;
    grid-column: unset;
  }
  .patient-list-wrapper .grid-row-2 {
    grid-row: 2;
    grid-column: unset;
  }
  .patient-list-wrapper .grid-row-3 {
    grid-row: 3;
    grid-column: unset;
  }
  .patient-list-wrapper .grid-row-4 {
    grid-row: 4;
    grid-column: unset;
  }
  .patient-list-wrapper .grid-row-5 {
    grid-row: 5;
    grid-column: unset;
  }
  .patient-list-wrapper > .col-sm-5 {
    width: auto;
  }
  .patient-list-wrapper .wrapper-grid-2 {
    grid-row: 2;
    grid-column: 1 / span 3;
  }
}
@media only screen and (max-width: 360px) {
  .js-orderDetailTable ul {
    margin-left: 10px !important;
  }
}
@media only screen and (max-width: 500px) {
  .js-clientTable .dtr-title {
    max-width: 45%;
  }
  .wishlist-product {
    white-space: normal;
    max-width: 50%;
  }
  .wishlist-order-actions {
    text-align: center;
  }
  .js-orderDetailTable .dtr-title {
    max-width: 10%;
  }
  .js-orderDetailTable .dtr-data div {
    max-width: 50%;
    white-space: normal;
  }
  .js-orderDetailTable span.dtr-data {
    margin-right: -5em;
  }
}
@media (max-width: 993px) {
  .patient-list-wrapper .tabbed-view-wrapper.u-marginTop30 {
    margin-top: 0;
    margin-bottom: 0.2em;
  }
  .tabbed-view-filters {
    display: flex;
    flex-wrap: wrap;
  }
  .tabbed-view-filters a {
    flex: 1 0 auto;
    text-align: center;
    height: unset;
    line-height: unset;
    margin: 0.3em 0 0 0;
    width: 48%;
  }
  .tabbed-view-filters a:first-child {
    width: 100%;
  }
  .tabbed-view-filters a:nth-child(2) {
    margin-right: 5px;
  }
  .tabbed-view-filters a:nth-child(3) {
    margin-left: 5px;
  }
  .tabbed-view-filters form {
    width: 100%;
  }
}
.nav-tabs {
  border-bottom: 2px solid #a2a19f;
  color: #a2a19f;
  display: none;
  font-size: 1.27em;
  font-weight: bold;
}
.nav-tabs > li {
  height: 35px;
}
.nav-tabs > li > a {
  padding-top: 4px;
  padding-bottom: 4px;
  margin-left: 5px;
  margin-right: 5px;
  background-color: #d5d4d3;
  border-color: #a2a19f;
}
.nav-tabs > li > a:hover {
  border-color: #a2a19f;
}
.nav > li > a:hover,
.nav > li > a:focus {
  background-color: #d5d4d3;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  color: #a2a19f;
  background-color: transparent;
  border-color: #a2a19f;
}
@media only screen and (max-width: 830px) {
  /* Allows up to 5 Tabs in a modal on Portrait 10-inch Tablet */
  .modal-content .nav-tabs > li > a {
    margin-left: 1px;
    margin-right: 1px;
  }
}
.nav-tabs--mobile {
  display: none;
}
.mobile-filter-toggle {
  display: none;
}
@media only screen and (max-width: 991px) {
  .mobile-filter-toggle {
    display: block;
    background: none;
    border: none;
    padding: 0;
  }
  .nav-tabs.js-categoryTabs {
    display: none;
  }
  .nav-tabs--mobile {
    display: block;
  }
}
.nav-tabs.mobile {
  background-color: #fff;
  font-size: 13px;
  display: flex;
  border-top: 2px solid #a2a19f;
  width: 100%;
}
.nav-tabs.mobile > li {
  height: auto;
  text-align: center;
  padding: 10px 5px;
  border-bottom: 3px solid transparent;
  width: 20%;
  flex: 1 0 20%;
}
.nav-tabs.mobile > li > a {
  background-color: transparent;
  border: 0;
  padding: 0;
}
.nav-tabs.mobile > li > a :hover {
  border: 0;
}
.nav-tabs.mobile > li.active {
  color: #547a8b;
  border-bottom: 3px solid #547a8b;
}
.nav-tabs.mobile > li span {
  display: block;
}
.nav-tabs.mobile > li span.glyphicon {
  font-size: 11px;
  margin-top: 2px;
}
@media (max-width: 575px) {
  .tabbed-view-filters {
    display: flex;
    flex-wrap: wrap;
  }
  .tabbed-view-filters a {
    flex: 1 0 auto;
    text-align: center;
    height: unset;
    line-height: unset;
    margin: 0.3em 0 0 0;
    width: 48%;
  }
  .tabbed-view-filters a:first-child {
    width: 100%;
  }
  .tabbed-view-filters a:nth-child(2) {
    margin-right: 5px;
  }
  .tabbed-view-filters a:nth-child(3) {
    margin-left: 5px;
  }
  .tabbed-view-filters form {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .nav-tabs {
    display: block;
  }
  .nav-tabs.mobile {
    display: none;
  }
}
/*
 * Tiles
 */
.tile {
  width: 100%;
  position: relative;
  margin-top: 20px;
  display: table;
}
.tile-row {
  display: table-row;
}
.tile-headline {
  font-size: 1.45em;
  text-align: center;
  font-family: "Humanist Condensed Bold";
  background-color: rgba(255, 255, 255, 0.5);
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  padding: 15px 10px;
}
.tile-headline--large {
  height: 80px;
}
.tile-headline--medium {
  height: 60px;
}
.tileDropdown {
  bottom: 30px;
  position: absolute;
  height: 40px;
  right: 0;
  width: 100%;
}
.tile--category {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-box-shadow: 0 0 10px 1px #ddd;
  -moz-box-shadow: 0 0 10px 1px #ddd;
  box-shadow: 0 0 10px 1px #ddd;
}
.tile-md--large {
  height: 300px;
}
.tile-md--medium {
  height: 300px;
}
.tile-arrow {
  padding: 10px 14px;
  color: #ffffff;
  background-color: #a2a19f;
  position: absolute;
  bottom: 0;
  right: 0;
}
.tile:hover .tile-arrow {
  background-color: #547a8b;
}
.tile--product {
  padding: 10px 20px 15px 20px;
  border: 1px solid #d5d4d3;
  margin-top: -1px;
  margin-left: -1px;
  cursor: pointer;
  z-index: 1;
}
.tile--product:hover {
  z-index: 2;
  /* The color is set below by the certain brand-classes */
}
.tile--product-right,
.tile--product-left {
  height: 300px;
  position: relative;
  float: left;
}
.tile-col--upper,
.tile-col--lower,
.tile-col--lower-price {
  position: relative;
}
.tile-col--upper {
  height: 33%;
}
.tile-col--lower {
  height: 66%;
  padding-top: 20px;
  font-size: 1.02em;
}
.tile-col--lower-price {
  padding-top: 5px;
}
.tile--product-left {
  /* Left container */
  width: 55%;
  padding-bottom: 30px;
  vertical-align: middle;
}
.tile--product-right {
  /* Right container */
  z-index: 1;
  width: 45%;
  color: #8c8c8c;
  text-align: left;
}
.tile--product-left img,
.tile--product-right img {
  position: absolute;
  bottom: 0;
}
.tile--product-right img {
  width: 100%;
}
.tile--product-left img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 220px;
  margin-left: -10px;
  bottom: 30px;
}
.tile--product-information {
  color: #a2a19f;
  line-height: 28px;
}
.tile--product-information:hover {
  color: #8e8d8e;
  cursor: pointer;
}
.tile--product-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
}
.tile--product-size-icons {
  position: relative;
  z-index: 1;
}
.tile--product-size-icon {
  height: 25px;
  margin-left: 10px;
  float: right;
  font-size: 1.27em;
  color: #a2a19f;
}
.u-tileDropdown--margin {
  margin-top: 40px;
}
.u-tile--inputBottom {
  position: absolute;
  bottom: -5px;
  width: 100%;
  font-size: 0.9em;
}
/* Brand hover-colors */
.tile--product.memory:hover {
  border-color: #a69965;
}
.tile--product.lastofa:hover {
  border-color: #000000;
}
.tile--product.gilofa:hover {
  border-color: #001d3b;
}
.tile--product.omotion:hover {
  border-color: #b20b0f;
}
.tile--product.rathgeber-thermo:hover {
  /*border-color: @color-rathgeber;*/
  cursor: default;
}
.tile--product.zubehor:hover {
  /*border-color: @color-zubehor;*/
  cursor: default;
}
.tile--product.ofafit:hover {
  border-color: #898278;
}
.tile--product.o-motion:hover {
  border-color: #b20b0f;
}
.tile--product.standardmass:hover {
  border-color: #8c8c8c;
}
.tile--product.ofa-basic:hover {
  border-color: #8c8c8c;
}
.tile--product.ofa-basic-serie:hover {
  border-color: #8c8c8c;
}
.tile--product.pflege:hover {
  /*border-color: @color-pflege;*/
  cursor: default;
}
.tile--product.psb:hover {
  border-color: #d37125;
}
.tile--product.push:hover {
  border-color: #6d3e5a;
}
.tile--product.push-sports:hover {
  border-color: #00496F;
}
.tile--product.dynamics:hover {
  border-color: #6286c3;
}
.tile--product.dynamics-plus:hover {
  border-color: #6286c3;
}
.tile--product.advertising {
  /*border-color: @color-advertising;*/
  cursor: default;
}
.tile--product.ecosana:hover {
  border-color: #a69965;
}
.tile--unavailable {
  cursor: default;
}
.tile--product__unavailable {
  background-color: #363fc4;
  color: white;
  position: absolute;
  top: 6em;
  left: 3em;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  z-index: 1;
}
.tile--product__unavailable p {
  display: block;
  max-height: 80%;
  max-width: 82%;
  margin: 2rem auto auto;
  line-height: 1;
}
.js-supplementProduct .tile--product__unavailable {
  left: 2.5em;
}
.tile--grayscale__active .tile--product-left {
  filter: grayscale(1);
}
.tile--grayscale__active .tile--product-right,
.tile--grayscale__active .icon-ofa-icon-information {
  filter: contrast(0.2);
}
.col-lg-9 .mobile-quick-order.shadowBox {
  display: none;
}
@media (min-width: 1200px) {
  .tile-lg--large {
    height: 400px;
  }
  .tile-lg--medium {
    height: 300px;
  }
  .tile-col--lower {
    padding-top: 5px;
  }
}
@media (max-width: 1200px) {
  .tile-headline--large {
    height: 60px;
  }
  .tile-headline--medium {
    height: 60px;
  }
}
@media (max-width: 1600px) {
  .tile,
  .tile--product-right,
  .tile--product-left {
    height: 250px;
  }
  .u-tileDropdown--margin {
    margin-top: 10px;
  }
}
@media (max-width: 1400px) {
  .tile,
  .tile--product-right,
  .tile--product-left {
    height: 210px;
  }
  .tile--product-left img {
    max-height: 180px;
    max-width: 100%;
    width: auto;
  }
  .tile--product-footer {
    bottom: -10px;
  }
  .tile--product {
    padding: 10px 10px 15px 10px;
  }
  .u-tileDropdown--margin {
    margin-top: 5px;
  }
  .tile-col--lower {
    font-size: 0.9em;
  }
  .u-tile--inputBottom {
    bottom: -15px;
  }
  .tileDropdown {
    bottom: 15px;
  }
}
@media (max-width: 1300px) {
  .tile--product__unavailable {
    left: 2em;
  }
}
@media (max-width: 1200px) {
  .tile,
  .tile--product-right,
  .tile--product-left {
    height: 230px;
  }
  .u-tileDropdown--margin {
    margin-top: 25px;
  }
  .tile--product-footer {
    font-size: 0.9em;
  }
}
@media (max-width: 768px) {
  .tile,
  .tile--product-right,
  .tile--product-left {
    height: 200px;
  }
}
@media only screen and (max-width: 991px) {
  .tileDropdown {
    bottom: 10px;
  }
  .u-price-fontSupplement {
    margin-top: 0;
  }
  #mobile-filter-modal .mobile-filter-modal {
    max-width: 100%;
  }
  #mobile-filter-modal .mobile-filter-modal.modal-dialog .modal-content {
    min-height: 100vh;
    height: auto !important;
  }
  #mobile-filter-modal .mobile-filter-modal .modal-content .shadowBox {
    display: block !important;
  }
  .col-lg-9 .mobile-quick-order.shadowBox {
    display: block !important;
  }
  .modal-content .brandFilter {
    display: flex;
  }
  .shadowBox:not(.accountDetails):not(.hoverSearch):not(.loginOverlay) {
    display: none !important;
  }
  #app .flatknittingTile .shadowBox {
    display: inline-block !important;
  }
  .catalogue-tiles-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
  .catalogue-tiles-wrapper > div {
    width: 100%;
  }
  .tile-col--lower {
    hyphens: auto;
  }
  .mobile-quick-order .shadowBox-headline {
    margin: 0.3em 0;
  }
  .mobile-quick-order button {
    width: 100%;
    max-width: 50px;
  }
  .catalogue-wrapper {
    max-width: 90%;
    margin: auto;
  }
  .client-order-filter-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 0.2fr;
  }
  .client-order-filter-wrapper > * {
    margin-top: 20px !important;
  }
  .client-order-filter-wrapper > div {
    margin-left: 0;
  }
  .tile--product-size-icon {
    float: none;
  }
}
@media only screen and (max-width: 500px) {
  .client-order-filter-wrapper {
    grid-template-columns: 1fr 0.2fr;
    grid-template-rows: 1fr 1fr;
  }
  .client-order-filter-wrapper > h3 {
    grid-column: 1;
    grid-row: 1;
  }
  .client-order-filter-wrapper > div {
    grid-column: 1;
    grid-row: 2;
    margin-top: 0 !important;
  }
  .client-order-filter-wrapper > button {
    grid-column: 2;
    grid-row: 1;
  }
}
@media only screen and (max-width: 585px) {
  .catalogue-tiles-wrapper {
    display: grid;
    grid-template-columns: 1fr;
  }
  .product-modal {
    min-height: 100vh;
    height: auto;
  }
}
@media only screen and (max-width: 392px) {
  .client-order-filter-wrapper .u-light {
    margin-left: 0 !important;
  }
}
.tooltip-inner {
  background-color: #547a8b;
  max-width: 250px;
}
.tooltip-arrow {
  border-bottom-color: #547a8b !important;
}
[data-toggle="tooltip-image"] + .tooltip {
  opacity: 1;
}
[data-toggle="tooltip-image"] + .tooltip .tooltip-inner {
  background-color: #fff;
  opacity: 1;
  min-width: 100px;
  height: 100px;
  line-height: 92px;
  -webkit-box-shadow: 0 0 10px 1px #ddd;
  -moz-box-shadow: 0 0 10px 1px #ddd;
  box-shadow: 0 0 10px 1px #ddd;
}
[data-toggle="tooltip-image"] + .tooltip .tooltip-arrow {
  display: none;
}
[data-toggle="tooltip-image"] input,
[data-toggle="tooltip-image"] label {
  vertical-align: top;
}
.video-js {
  cursor: pointer;
}
.video-js .vjs-big-play-button {
  top: 50%;
  left: 50%;
  margin: 1em auto auto -1.5em;
}
.vjs-paused.vjs-has-started .vjs-big-play-button {
  display: block;
}
.flat-row {
  width: 100%;
}
.flat-row:first-child {
  margin-top: 50px;
}
.flat-head {
  background-color: #000000;
  color: white;
  line-height: 50px;
  float: left;
  width: 50%;
  padding-left: 8px;
  font-size: 1.27em;
}
.flat-data:first-child {
  border-right: 1px solid #000000;
  border-left: 1px solid #000000;
}
.flat-data:not(:first-child) {
  border-right: 1px solid #000000;
}
.flat-data {
  float: left;
  width: 50%;
  height: 35px;
  text-align: center;
  border-bottom: 1px solid #000000;
}
.flat-label {
  float: left;
  width: 40%;
  line-height: 35px;
  border-right: 1px solid #000000;
}
.flat-label-print {
  float: left;
  width: 40%;
  line-height: 35px;
  border-right: 1px solid #000000;
  width: 35%;
}
.flat-input {
  float: left;
  width: 60%;
}
.flat-input input,
.flat-input .printInput {
  height: 34px;
  width: 100%;
  border: 0;
}
.flat-input input.is-faulty,
.flat-input .printInput.is-faulty {
  border: 1px solid #000000;
  color: #000000;
  font-weight: bold;
}
.flat-input .printInput {
  line-height: 34px;
}
.flat-data--noBorder {
  border: 0 !important;
}
.flat-data--borderLeft {
  border: 0 !important;
  border-left: 1px solid #000000 !important;
}
.flat-data--borderBottom {
  border: 0 !important;
  border-bottom: 1px solid #000000 !important;
}
.flat-data--borderBottomRight {
  border: 0 !important;
  border-bottom: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
}
.flat-data--borderRight {
  border: 0 !important;
  border-right: 1px solid #000000 !important;
}
.flatknitting-imageInput input {
  background-color: transparent;
  border-color: transparent;
}
.flatknitting-imageInput input#productionNumber {
  border-color: #a2a19f;
}
.flatknitting-imageInput input:focus {
  background-color: transparent;
  border-color: transparent;
}
.flatknittingForm .inputGroup-radioLabel {
  margin-bottom: 10px;
}
.flatknittingFootTable th {
  background-color: #000000;
  color: #ffffff;
  padding: 3px 3px;
}
.flatknittingFootTable td {
  border: 1px solid #000000;
  height: 30px;
  padding: 0 3px;
}
.flatknittingFootTable input {
  margin: 0;
  width: 70px;
  height: 30px;
  border: 0;
}
.flatknittingFootTable input.is-faulty {
  border: 1px solid #000000;
  color: #000000;
  font-weight: bold;
}
.flatknitting-measures {
  position: relative;
}
.vue-tooltip-theme {
  opacity: 1;
}
.measurement-btn-row {
  display: flex;
  justify-content: space-between;
}
.flatknitting-measurement-modal .modal-dialog {
  width: fit-content;
  width: -moz-fit-content;
}
@media only screen and (max-width: 991px) {
  .scrollmagic-pin-spacer {
    padding-top: 0 !important;
  }
}
@media only screen and (max-width: 975px) {
  #measurement-modal {
    overflow-x: scroll;
  }
  .flatknitting-measures img {
    display: none;
  }
}
.modal-body-announcement {
  font-size: 1.27em;
}
.modal-announcement img {
  max-width: 100%;
}
#modal-announcement a {
  color: #547a8b;
}
/*
 * Card Stack
 */
.card-stack {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 450px;
  height: 169px;
}
.card-stack .buttons {
  display: none;
  position: absolute;
  width: 35px;
  height: 35px;
  left: 0;
  top: 55%;
  color: #ffffff;
  text-align: center;
  line-height: 35px;
  text-decoration: none;
  font-size: 22px;
  z-index: 100;
  outline: none;
  transition: all 0.2s ease;
}
.card-stack .buttons:hover {
  transform: scale(1.3, 1.3);
}
.card-stack .prev {
  left: 15px;
  right: auto;
}
.card-stack .next {
  left: auto;
  right: 15px;
}
.card-stack .card-list {
  width: 300px;
}
.card-stack .card-list li {
  transition: all 100ms ease-in-out;
  border-radius: 2px;
  position: absolute;
  list-style: none;
  height: 135px;
  background-color: #547a8b;
  color: #ffffff;
  -webkit-box-shadow: 0 2px 15px 1px rgba(225, 225, 225, 0.5);
  box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.5);
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  display: none;
}
.card-stack .card-list li button.close {
  position: absolute;
  top: 0;
  right: 5px;
  color: #ffffff;
  opacity: 0.8;
  font-weight: normal;
}
.card-stack .card-list li button.close:hover {
  opacity: 1;
}
.card-stack .card-list li .read-more {
  position: absolute;
  right: 60px;
  text-decoration: underline;
  font-weight: bold;
  float: right;
}
.card-stack .card-list li .read-more:focus {
  outline: none;
}
.card-stack .card-list li .show-later {
  position: absolute;
  left: 60px;
  text-decoration: underline;
  font-weight: bold;
}
.card-stack .card-list li:nth-child(1) {
  display: block;
}
.card-stack .card-list li:nth-child(2) {
  display: block;
}
.card-stack .card-list li:nth-child(3) {
  display: block;
}
.card-stack:hover > .buttons.prev {
  display: block;
  animation: bounceInLeft 200ms;
}
.card-stack:hover > .buttons.next {
  display: block;
  animation: bounceInRight 200ms;
}
.transformThis {
  animation: scaleDown 500ms;
}
.transformPrev {
  animation: scaleUp 100ms;
  display: none;
}
@keyframes scaleUp {
  0% {
    transform: scale(1.2) translateY(50px);
    opacity: 0;
  }
  20% {
    transform: scale(1.15) translateY(40px);
    opacity: 0.1;
  }
  40% {
    transform: scale(1.1) translateY(30px);
    opacity: 0.2;
  }
  60% {
    transform: scale(1.05) translateY(20px);
    opacity: 0.4;
  }
  80% {
    transform: scale(1.01) translateY(10px);
    opacity: 0.8;
  }
  100% {
    transform: scale(1) translateY(0);
    opacity: 1;
  }
}
@keyframes scaleDown {
  0% {
    transform: scale(1) translateY(0);
    opacity: 1;
  }
  20% {
    transform: scale(1.01) translateY(20px);
    opacity: 0.8;
  }
  40% {
    transform: scale(1.05) translateY(40px);
    opacity: 0.4;
  }
  60% {
    transform: scale(1.1) translateY(60px);
    opacity: 0.2;
  }
  80% {
    transform: scale(1.15) translateY(80px);
    opacity: 0.1;
  }
  100% {
    transform: scale(1.2) translateY(100px);
    opacity: 0;
  }
}
@keyframes scaleCard {
  0% {
    top: 5px;
  }
  100% {
    top: 24px;
  }
}
@keyframes bounceInLeft {
  0% {
    opacity: 0;
    transform: translateX(40px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes bounceInRight {
  0% {
    opacity: 0;
    transform: translateX(-40px);
  }
  100% {
    transform: translateX(0);
  }
}
.shipping-addresses-wrapper-mobile {
  display: none;
}
@media only screen and (max-width: 991px) {
  .shipping-addresses-wrapper,
  .shipping-addresses-hint-desktop {
    max-width: 90%;
    margin: auto;
  }
}
@media only screen and (max-width: 500px) {
  .shipping-addresses-wrapper {
    display: none;
  }
  .shipping-addresses-hint-desktop {
    display: none;
  }
  .shipping-addresses-wrapper-mobile {
    display: block;
    max-width: 95%;
    margin: auto;
  }
  .shipping-addresses-wrapper-mobile .shipping-addresses-hint {
    display: block;
    margin-bottom: 0.5em;
  }
  .shipping-addresses-wrapper-mobile .shipping-addresses-hint .btn.btn--primary {
    width: 100%;
    text-align: center;
  }
  .shipping-addresses-wrapper-mobile .shipping-addresses-hint .hint {
    position: relative;
    transform: none;
    text-align: center;
  }
  .shipping-addresses-wrapper-mobile .shippingAddress {
    padding: 1em 1em 0 1em;
    border-top: 1px solid #000;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 0.5em;
  }
  .shipping-addresses-wrapper-mobile .shippingAddress span:nth-child(odd) {
    font-family: "Humanist Condensed Bold";
  }
  .shipping-addresses-wrapper-mobile .shippingAddress div:last-child {
    border-top: 1px solid #d5d4d3;
    display: flex;
    justify-content: space-around;
    grid-column: 1 / span 2;
    padding-bottom: 0.5em;
    padding-top: 0.5em;
  }
  .shipping-addresses-wrapper-mobile .shippingAddress:nth-child(odd) {
    background-color: #eaeae9;
  }
  .shipping-addresses-wrapper-mobile .shippingAddress:last-child {
    border-bottom: 1px solid #000;
  }
}
@media only screen and (max-width: 500px) {
  .order-success-btn-wrapper {
    margin-top: 0 !important;
  }
}
@media only screen and (max-width: 991px) {
  .deliveryAddress {
    white-space: pre-line;
    display: inline-block;
    max-width: 40%;
    vertical-align: top;
  }
  .deliveryAddress-th {
    vertical-align: top;
  }
}
@media only screen and (max-width: 500px) {
  .order-list-modal {
    width: 100% !important;
    min-height: 100vh;
    max-width: unset;
  }
}
.cms-toolbar-expanded .navbar-fixed-top {
  top: 46px;
}
.cms-toolbar-expanded body {
  padding-top: 106px;
}
/*
 * Utilities for styling
 */
/* Display */
.u-displayBlock {
  display: block;
}
.u-inlineBlock {
  display: inline-block;
}
.u-inlineBlock-important {
  display: inline-block !important;
}
.u-inline {
  display: inline;
}
.u-displayNone {
  display: none;
}
.u-smaller {
  font-size: 0.8em;
}
.u-small {
  font-size: 0.9em;
}
.u-base {
  font-size: 1em;
}
.u-floatRight {
  float: right;
}
.u-floatLeft {
  float: left;
}
/* Margins */
.u-marginTop20--negative {
  margin-top: -20px;
}
.u-marginTop35--negative {
  margin-top: -35px;
}
.u-marginTop50--negative {
  margin-top: -50px;
}
.u-marginTop0 {
  margin-top: 0;
}
.u-marginTop5 {
  margin-top: 5px;
}
.u-marginBottom0 {
  margin-bottom: 0;
}
.u-marginHeight5 {
  margin: 5px 0;
}
.u-marginBottom5 {
  margin-bottom: 5px;
}
@media (max-width: 767px) {
  .u-xs-marginBottom20 {
    margin-bottom: 20px;
  }
}
.u-marginLeft10 {
  margin-left: 10px;
}
.u-marginRight10 {
  margin-right: 10px;
}
.u-marginTop10 {
  margin-top: 10px;
}
.u-marginBottom10 {
  margin-bottom: 10px;
}
.u-marginWidth10 {
  margin: 0 10px;
}
.u-marginHeight10 {
  margin: 10px 0;
}
.u-marginLeft15 {
  margin-left: 15px;
}
.u-marginRight15 {
  margin-right: 15px;
}
.u-marginTop15 {
  margin-top: 15px;
}
.u-marginBottom15 {
  margin-bottom: 15px;
}
.u-marginTop18 {
  margin-top: 18px;
}
.u-marginLeft20 {
  margin-left: 20px;
}
.u-marginLeft200 {
  margin-left: 200px;
}
.u-marginRight20 {
  margin-right: 20px;
}
.u-marginTop20 {
  margin-top: 20px;
}
.u-marginBottom20 {
  margin-bottom: 20px;
}
.u-marginWidth20 {
  margin: 0 20px;
}
.u-marginHeight20 {
  margin: 20px 0;
}
.u-marginLeft25 {
  margin-left: 25px;
}
.u-marginRight25 {
  margin-right: 25px;
}
.u-marginTop25 {
  margin-top: 25px;
}
.u-marginBottom25 {
  margin-bottom: 25px;
}
.u-marginWidth25 {
  margin: 0 25px;
}
.u-marginHeight25 {
  margin: 25px 0;
}
.u-marginLeft30 {
  margin-left: 30px;
}
.u-marginRight30 {
  margin-right: 30px;
}
.u-marginTop30 {
  margin-top: 30px;
}
.u-marginBottom30 {
  margin-bottom: 30px;
}
.u-marginWidth30 {
  margin: 0 30px;
}
.u-marginHeight30 {
  margin: 30px 0;
}
.u-marginTop40 {
  margin-top: 40px;
}
.u-marginRight40 {
  margin-right: 40px;
}
.u-marginLeft50 {
  margin-left: 50px;
}
.u-marginRight50 {
  margin-right: 50px;
}
.u-marginTop50 {
  margin-top: 50px;
}
.u-marginBottom50 {
  margin-bottom: 50px;
}
.u-marginWidth50 {
  margin: 0 50px;
}
.u-marginHeight50 {
  margin: 50px 0;
}
.u-marginTop80 {
  margin-top: 80px;
}
.u-marginTop100 {
  margin-top: 100px;
}
.u-marginTop150 {
  margin-top: 150px;
}
/* Paddings */
.u-paddingRight0 {
  padding-right: 0;
}
.u-paddingLeft10 {
  padding-left: 10px;
}
.u-paddingRight10 {
  padding-right: 10px;
}
.u-paddingBottom10 {
  padding-bottom: 10px;
}
.u-paddingTop10 {
  padding-top: 10px;
}
.u-paddingWidth10 {
  padding: 0 10px;
}
.u-paddingHeight10 {
  padding: 10px 0;
}
.u-paddingLeft15 {
  padding-left: 15px;
}
.u-paddingLeft20 {
  padding-left: 20px;
}
.u-paddingTop20 {
  padding-top: 20px;
}
.u-paddingBottom20 {
  padding-bottom: 20px;
}
.u-paddingRight20 {
  padding-right: 20px;
}
.u-paddingWidth20 {
  padding: 0 20px;
}
.u-paddingHeight20 {
  padding: 20px 0;
}
.u-paddingLeft25 {
  padding-left: 25px;
}
.u-paddingTop25 {
  padding-top: 25px;
}
.u-paddingBottom25 {
  padding-bottom: 25px;
}
.u-paddingRight25 {
  padding-right: 25px;
}
.u-paddingWidth25 {
  padding: 0 25px;
}
.u-paddingHeight25 {
  padding: 25px 0;
}
.u-paddingLeft50 {
  padding-left: 50px;
}
.u-paddingRight50 {
  padding-right: 50px;
}
.u-paddingWidth50 {
  padding: 0 50px;
}
.u-paddingHeight50 {
  padding: 50px 0;
}
.u-paddingLeft200 {
  padding-left: 200px;
}
.u-noPaddingNoMargin {
  padding: 0;
  margin: 0;
}
.u-noPadding {
  padding: 0 !important;
}
.u-noMargin {
  margin: 0 !important;
}
/* Width & Height */
.u-maxWidth100 {
  max-width: 100%;
}
.u-maxWidth50 {
  max-width: 50%;
}
.u-maxWidth30 {
  max-width: 30%;
}
.u-maxWidth300 {
  max-width: 300px;
}
.u-maxHeight100 {
  max-height: 100%;
}
.u-maxHeight50 {
  max-height: 50%;
}
.u-maxHeight30 {
  max-height: 30%;
}
.u-wAuto {
  width: auto;
}
.u-w40 {
  width: 40px;
}
.u-w50 {
  width: 50px;
}
.u-w50i {
  width: 50px !important;
}
.u-w70 {
  width: 70px;
}
.u-w80 {
  width: 80px;
}
.u-w90 {
  width: 80px;
}
.u-w100 {
  width: 100px;
}
.u-w120 {
  width: 120px;
}
.u-w130 {
  width: 130px;
}
.u-w150 {
  width: 150px;
}
.u-w160 {
  width: 160px;
}
.u-w200 {
  width: 200px;
}
.u-w240 {
  width: 240px;
}
.u-w250 {
  width: 250px;
}
.u-w300 {
  width: 300px;
}
.u-w330 {
  width: 330px;
}
.u-w340 {
  width: 340px;
}
.u-w380 {
  width: 380px;
}
.u-w20P {
  width: 20%;
}
.u-w40P {
  width: 40%;
}
.u-w80P {
  width: 80%;
}
.u-w60P {
  width: 60%;
}
.u-w100P {
  width: 100%;
}
.u-h100 {
  height: 100px;
}
.u-h20 {
  height: 20px;
}
.u-h30 {
  height: 30px;
}
.u-h50 {
  height: 50px;
}
.u-h80 {
  height: 80px;
}
.u-h70P {
  height: 70%;
}
.u-h100P {
  height: 100%;
}
.u-h250 {
  height: 250px;
}
.u-minHeight150 {
  min-height: 150px;
}
.u-minHeight450 {
  min-height: 450px;
}
/* Visibility */
.u-visibilityHidden {
  visibility: hidden;
  opacity: 0;
}
.u-overflow--hidden {
  overflow: hidden;
}
.u-overflow--auto {
  overflow: auto;
}
/* Centering */
/* This uses the merge-functionality (+_) of less on the transform declarations. */
.u-centerContainer {
  position: relative;
}
.u-centerVertically {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.u-centerHorizontally {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.u-centerVerticallyHorizontally {
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  position: absolute;
  left: 50%;
}
/* Transitions */
.u-transitionEaseInOut2 {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.u-underline {
  text-decoration: underline;
}
.u-bold {
  font-family: "Humanist Condensed Bold";
}
.u-bold-important {
  font-family: "Humanist Condensed Bold" !important;
}
.u-light {
  font-family: "Humanist Condensed Light";
}
.u-italic {
  font-family: "Humanist Condensed Italic";
}
.u-uppercase {
  text-transform: uppercase;
}
.u-fontSizeLarge {
  font-size: 1.27em;
}
.u-fontSizeSmall {
  font-size: 0.8em;
}
.u-fontSizeSmall-hint {
  font-size: 0.9em;
}
.u-lineThrough {
  text-decoration: line-through;
}
.u-colorBrandNeutral {
  color: #a2a19f;
}
.u-desaturate {
  /* You're trying to find out how the grayscaling works?
   Because IE (and actually FF also) suck, grayscaling is done via https://github.com/karlhorky/gray/
   It's called in brand_filter.html currently. */
  opacity: 0.5;
  cursor: default;
}
.u-desaturate:before {
  cursor: default;
}
.u-listStyleDash {
  margin-top: 5px;
  list-style: none;
  margin-left: 0;
  padding-left: 1em;
}
.u-listStyleDash > li:before {
  display: inline-block;
  content: '\2013';
  width: 1em;
  margin-left: -1em;
  margin-right: -0.5em;
}
.u-colorBrandBlack {
  color: #000000 !important;
}
.u-color-brandWarning {
  color: #b0053a;
}
.u-color-blueLightest {
  color: #b6c6ce;
}
.u-color-blueLighter {
  color: #95acb8;
}
.u-color-blueLight {
  color: #7391a0;
}
.u-color-blueNeutral {
  color: #547a8b;
}
.u-color-blueDark {
  color: #346274;
}
.u-color-blueDarker {
  color: #134b5c;
}
.u-color-blueDarkest {
  color: #033343;
}
/*
 * Brand-Color for Font, Background and Border
 */
.u-colorbrandNeutral-font {
  color: #a2a19f;
}
.u-colorbrandNeutral-background {
  background-color: #a2a19f;
}
.u-colorbrandNeutral-border {
  border-color: #a2a19f;
}
.u-colorBrandBlack-font {
  color: #000000;
}
.u-colorBrandBlack-background {
  background-color: #000000;
}
.u-colorBrandBlack-border {
  border-color: #000000;
}
.u-colorBrandDark-font {
  color: #8e8d8e;
}
.u-color-memory-font {
  color: #a69965;
}
.u-color-memory-background {
  background-color: #a69965;
}
.u-color-memory-border {
  border-color: #a69965;
}
.u-color-MemoryAloevera-font {
  color: #8a9644;
}
.u-color-MemoryAloevera-background {
  background-color: #8a9644;
}
.u-color-MemoryAloevera-border {
  border-color: #8a9644;
}
.u-color-lastofa-font {
  color: #000000;
}
.u-color-lastofa-background {
  background-color: #000000;
}
.u-color-lastofa-border {
  border-color: #000000;
}
.u-color-gilofa-font {
  color: #001d3b;
}
.u-color-gilofa-background {
  background-color: #001d3b;
}
.u-color-gilofa-border {
  border-color: #001d3b;
}
.u-color-GilofaAdditional-font {
  color: #a6b0bb;
}
.u-color-GilofaAdditional-background {
  background-color: #a6b0bb;
}
.u-color-GilofaAdditional-border {
  border-color: #a6b0bb;
}
.u-color-o-motion-font {
  color: #b20b0f;
}
.u-color-o-motion-background {
  background-color: #b20b0f;
}
.u-color-o-motion-border {
  border-color: #b20b0f;
}
.u-color-rathgeber-thermo-font {
  color: #898278;
}
.u-color-rathgeber-thermo-background {
  background-color: #898278;
}
.u-color-rathgeber-thermo-border {
  border-color: #898278;
}
.u-color-Ofafit-font {
  color: #898278;
}
.u-color-Ofafit-background {
  background-color: #898278;
}
.u-color-Ofafit-border {
  border-color: #898278;
}
.u-color-standardma-font {
  color: #8c8c8c;
}
.u-color-standardma-background {
  background-color: #8c8c8c;
}
.u-color-standardma-border {
  border-color: #8c8c8c;
}
.u-color-psb-font {
  color: #d37125;
}
.u-color-psb-background {
  background-color: #d37125;
}
.u-color-psb-border {
  border-color: #d37125;
}
.u-color-push-font {
  color: #6d3e5a;
}
.u-color-push-background {
  background-color: #6d3e5a;
}
.u-color-push-border {
  border-color: #6d3e5a;
}
.u-color-push-sports-font {
  color: #00496F;
}
.u-color-push-sports-background {
  background-color: #00496F;
}
.u-color-push-sports-border {
  border-color: #00496F;
}
.u-color-dynamics-font {
  color: #6286c3;
}
.u-color-dynamics-background {
  background-color: #6286c3;
}
.u-color-dynamics-border {
  border-color: #6286c3;
}
.u-color-dynamics-plus-font {
  color: #6286c3;
}
.u-color-dynamics-plus-background {
  background-color: #6286c3;
}
.u-color-dynamics-plus-border {
  border-color: #6286c3;
}
.u-color-pflege-font {
  color: #898278;
}
.u-color-pflege-background {
  background-color: #898278;
}
.u-color-pflege-border {
  border-color: #898278;
}
.u-color-zubehor-font {
  color: #898278;
}
.u-color-zubehor-background {
  background-color: #898278;
}
.u-color-zubehor-border {
  border-color: #898278;
}
.u-cursorPointer {
  cursor: pointer;
}
.u-verticalAlignTop {
  vertical-align: top;
}
.u-verticalAlignMiddle {
  vertical-align: middle;
}
.u-noClick {
  cursor: default !important;
}
.u-noPointerEvent {
  pointer-events: none;
}
.u-mirror {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.u-mirror:before {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.u-ofaColor {
  color: #a2a19f;
}
.u-ofaColor:hover {
  color: #8e8d8e;
}
.u-pullTop10 {
  margin-top: -10px;
}
.u-pullTop20 {
  margin-top: -20px;
}
.u-pullTop30 {
  margin-top: -30px;
}
.u-pullBottom10 {
  margin-bottom: -10px;
}
.u-consigneePatient {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border-width: 2px;
  border-color: #a2a19f;
  color: #a2a19f;
  border-style: solid;
  width: 20px;
  text-align: center;
  line-height: 16px;
  height: 20px;
  display: inline-block;
  margin-right: 5px;
}
.u-textAlign--center {
  text-align: center;
}
.u-textAlign--left {
  text-align: left;
}
.u-textAlign--right {
  text-align: right;
}
.u-subTitle {
  font-size: 1.27em;
  display: inline-block;
}
.u-noBorder {
  border: 0;
}
.u-borderRight {
  border-right: 1px solid #000000;
}
.u-warning {
  color: #b0053a;
  font-family: "Humanist Condensed Italic";
}
.u-success {
  color: #2a7216;
}
.u-underline {
  text-decoration: underline;
}
.u-positionRelative {
  position: relative;
}
.u-specificationWarning {
  font-family: "Humanist Condensed Italic";
  color: #b0053a;
  margin-top: -15px;
  margin-bottom: 10px;
}
.u-measurementLetter {
  font-family: "AGaramondPro-Italic";
}
.u-disabled {
  opacity: 0.5;
}
.u-measurementKeyValue {
  text-align: right;
}
.u-whiteSpace--normal {
  white-space: normal;
}
@media print {
  .u-noPrint {
    display: none;
  }
  .u-noWrap {
    white-space: nowrap;
    margin-right: 10px;
  }
}
@media (max-width: 1024px) {
  .u-applyLeg {
    font-size: 0.8em;
  }
  .u-measurementKeyValue {
    text-align: left;
  }
}
.u-colorTitle {
  white-space: normal;
  vertical-align: middle;
  display: inline-block;
}
[data-toggle="tooltip"] {
  cursor: pointer;
}
.hiddenAnchor {
  display: block;
  position: relative;
  top: -89px;
  /* size of the fixed header */
  visibility: hidden;
}
.u-noWrap {
  white-space: nowrap;
}
@media only screen and (max-width: 500px) {
  .u-wAuto {
    width: auto;
  }
  .u-w40 {
    width: 100%;
    max-width: 40px;
  }
  .u-w50 {
    width: 100%;
    max-width: 50px;
  }
  .u-w50i {
    width: 100%;
    max-width: 50px !important;
  }
  .u-w70 {
    width: 100%;
    max-width: 70px;
  }
  .u-w80 {
    width: 100%;
    max-width: 80px;
  }
  .u-w90 {
    width: 100%;
    max-width: 80px;
  }
  .u-w100 {
    width: 100%;
    max-width: 100px;
  }
  .u-w120 {
    width: 100%;
    max-width: 120px;
  }
  .u-w130 {
    width: 100%;
    max-width: 130px;
  }
  .u-w150 {
    width: 100%;
    max-width: 150px;
  }
  .u-w160 {
    width: 100%;
    max-width: 160px;
  }
  .u-w200 {
    width: 100%;
    max-width: 200px;
  }
  .u-w240 {
    width: 100%;
    max-width: 240px;
  }
  .u-w250 {
    width: 100%;
    max-width: 250px;
  }
  .u-w300 {
    width: 100%;
    max-width: 300px;
  }
  .u-w330 {
    width: 100%;
    max-width: 330px;
  }
  .u-w340 {
    width: 100%;
    max-width: 340px;
  }
  .u-w380 {
    width: 100%;
    max-width: 380px;
  }
  .u-w20P {
    width: 100%;
    max-width: 20%;
  }
  .u-w40P {
    width: 100%;
    max-width: 40%;
  }
  .u-w80P {
    width: 100%;
    max-width: 80%;
  }
  .u-w60P {
    width: 100%;
    max-width: 60%;
  }
  .u-w100P {
    width: 100%;
  }
  .u-h100 {
    height: 100px;
  }
  .u-h20 {
    height: 20px;
  }
  .u-h30 {
    height: 30px;
  }
  .u-h50 {
    height: 50px;
  }
  .u-h80 {
    height: 80px;
  }
  .u-h70P {
    height: 70%;
  }
  .u-h100P {
    height: 100%;
  }
  .u-h250 {
    height: 250px;
  }
  .u-minHeight150 {
    min-height: 150px;
  }
  .u-minHeight450 {
    min-height: 450px;
  }
}
@media (max-width: 500px) {
  .u-noPaddingMobile {
    padding: 0 !important;
  }
}
.hamburger path,
.mobile-basket-link path,
.mobile-navbar path {
  display: inline !important;
}
html,
body {
  height: 100%;
}
strong {
  font-family: "Humanist Condensed Bold";
}
a:hover,
a:focus {
  color: inherit;
}
.container-fluid {
  min-height: 100%;
}
.col-xs-15 {
  width: 20%;
  float: left;
}
.col-xs-25 {
  width: 40%;
  float: left;
}
.text-left + .tooltip > .tooltip-inner {
  text-align: left;
}
.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}
.row-eq-height > [class*='col-'] {
  display: flex;
  flex-direction: column;
}
.row-eq-height img {
  max-width: 100%;
  align-self: baseline;
}
.row-eq-height .productSpec-alternateTile {
  height: 100%;
}
@media (min-width: 768px) {
  .col-sm-15 {
    width: 20%;
    float: left;
  }
  .col-sm-25 {
    width: 40%;
    float: left;
  }
}
@media (min-width: 992px) {
  .col-md-15 {
    width: 20%;
    float: left;
  }
  .col-md-25 {
    width: 40%;
    float: left;
  }
  .col-lg-17 {
    width: 100%;
    float: left;
  }
  .col-lg-37 {
    width: 100%;
    float: left;
  }
  .col-lg-47 {
    width: 100%;
    float: left;
  }
}
@media (min-width: 1200px) {
  .col-lg-15 {
    width: 20%;
    float: left;
  }
  .col-lg-25 {
    width: 40%;
    float: left;
  }
  .col-lg-17 {
    width: 33.33333333%;
    float: left;
  }
  .col-md-17 {
    width: 100%;
    float: left;
  }
}
@media (max-width: 1150px) {
  body {
    padding-top: 110px;
  }
}
@media (min-width: 1440px) {
  .col-xl-2 {
    width: 16.66666666%;
    float: left;
  }
  .col-xl-10 {
    width: 83.33333333%;
    float: left;
  }
  .col-xl-8 {
    width: 66.66666666%;
    float: left;
  }
  .col-xl-6 {
    width: 50%;
    float: left;
  }
  .col-xl-offset-6 {
    margin-left: 50%;
  }
  .col-xl-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-xl-offset-2 {
    margin-left: 16.6666667%;
  }
}
@media (min-width: 1600px) {
  .col-lg-17 {
    width: 14.28571429%;
    float: left;
  }
  .col-lg-37 {
    width: 42.85714286%;
    float: left;
  }
  .col-lg-47 {
    width: 57.14285714%;
    float: left;
  }
  .col-xl-4 {
    width: 33.33333333%;
    float: left;
  }
}
ul.nav li.dropdown:hover > ul.dropdown-menu {
  display: block;
}
@page {
  size: A4;
}
@media print {
  nav {
    display: none;
  }
  .footer {
    display: none;
  }
  .breadcrumbs {
    display: none;
  }
  img {
    display: none;
  }
  .pagebreakBefore {
    page-break-before: always;
  }
  .select2 {
    display: none;
  }
  .inputGroup-radioInput,
  .inputGroup-checkBoxInput {
    display: inline-block !important;
    margin: 0;
  }
  .inputGroup-radioLabel:before,
  .inputGroup-checkBoxLabel:before {
    display: none;
  }
}
/* col-mdlg is used for the menu, to trigger custom screen-widths at which the menu is displayed in a full row */
@media (min-width: 1010px) {
  .col-mdlg-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-mdlg-10 {
    width: 83.33333333%;
  }
}
